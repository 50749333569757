import { INVESTIGATION_CREATED, INVEST_ERROR } from "../actions/investCreation";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case INVESTIGATION_CREATED:
      return {
        ...state,
        newInvest: action.payload,
      };
    case INVEST_ERROR:
      return {
        ...state,
        investError: action.payload,
      };
  }
  return state;
}
