import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { parse } from "qs";
import SignUp from "./SignUp";
import { signUpAction } from "../actions/signIn";
import StudyPagesAccountCreated from "../StudyPages/StudyPagesAccountCreated";
import { getDeepLink } from "../actions/deepLinks";
import { STUDYPAGES_BASE_URL } from "../middleware/networking";
import crypto from "crypto";
const STUDYPAGES_CLIENT_ID = process.env.REACT_APP_STUDYPAGES_CLIENT_ID;
const STUDYPAGES_CODE_VERIFIER = process.env.REACT_APP_STUDYPAGES_CODE_VERIFIER;
const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

const SignUpContainer = ({
  redirect,
  history,
  signUpAction,
  email,
  inviteToken,
  investigationIdentifier,
  appError,
  deepLinkData,
  getDeepLink,
  token,
  groupIdentifier,
}) => {
  const [studyPagesAccountCreated, setStudyPagesAccountCreated] =
    useState(false);
  const [linkData, setLinkData] = useState(undefined);
  const [localEmail, setEmail] = useState("");
  const handleSubmit = async (name, email, phone, captcha) => {
    setEmail(email);
    const response = await signUpAction({
      name,
      email,
      phone,
      captcha,
      token,
      groupIdentifier,
      investigationIdentifier,
    });
    if (response) {
      setStudyPagesAccountCreated(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getDeepLink(inviteToken, investigationIdentifier);
    };
    getData();
  }, [inviteToken, investigationIdentifier]);

  useEffect(() => {
    if (deepLinkData != null) {
      setLinkData(deepLinkData.payload);
    }
  }, [deepLinkData]);

  const handleLoginClick = () => {
    history.push("/login");
  };

  const handleStudyPagesOAuth = () => {
    const codeChallenge = crypto
      .createHash("sha256")
      .update(STUDYPAGES_CODE_VERIFIER)
      .digest("base64")
      .replace("=", "");
    const state = token
      ? JSON.stringify({ token, groupIdentifier })
      : "random_state";
    let oAuthUrl = `${STUDYPAGES_BASE_URL}/oauth/authorize/?client_id=${STUDYPAGES_CLIENT_ID}&state=${state}&response_type=code&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${FRONTEND_BASE_URL}/studypages/success&email=${localEmail}`;
    window.location.replace(oAuthUrl);
  };

  useEffect(() => {
    if (appError !== null && appError.response !== undefined) {
      if (
        appError.response.data.statusCode === 403 &&
        appError.response.data.message.includes("email address already exists")
      ) {
        handleStudyPagesOAuth();
      }
    }
  }, [appError]);

  return studyPagesAccountCreated ? (
    <StudyPagesAccountCreated />
  ) : (
    <SignUp
      history={history}
      onSubmit={handleSubmit}
      onLoginClick={handleLoginClick}
      emailId={email}
      appError={appError}
      linkData={linkData}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { email, invite, investigationIdentifier, token, groupIdentifier } =
    ownProps.location
      ? parse(ownProps.location.search, {
          ignoreQueryPrefix: true,
        })
      : "";
  return {
    email,
    inviteToken: invite,
    investigationIdentifier,
    appError: state.error.appError,
    deepLinkData: state.deepLink.data,
    token,
    groupIdentifier,
  };
};

export default connect(mapStateToProps, {
  signUpAction,
  getDeepLink,
})(SignUpContainer);
