import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { checkAuth, signOutAction } from "./actions/signIn";

export default function (ComposedComponent) {
  class Authentication extends Component {
    constructor(props) {
      super(props);

      this.events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];

      for (let i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    componentWillMount() {
      this.props.checkAuth();
      if (!this.props.authenticated) {
        if (
          this.props.location &&
          this.props.location.pathname.includes("/e-consent-verification")
        ) {
          this.props.history.push({
            pathname: "/login",
            state: {
              eConsent: `${this.props.location.pathname}${this.props.location.search}`,
            },
          });
        } else {
          this.props.history.push("/login");
        }
      }
    }

    componentWillUpdate(nextProps) {
      this.props.checkAuth();
      if (!nextProps.authenticated) {
        this.props.history.push({
          pathname: "/login",
          state: {
            reason: this.props.fromSignOut ? "" : "timeout",
          },
        });
      }
    }

    clearTimeout = () => {
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
        this.logoutTimeout = setTimeout(this.logout, 15 * 60 * 1000);
        userData.validDate = new Date().getTime() + 15 * 60 * 1000;
        localStorage.setItem("user", JSON.stringify(userData));
      }
    };

    logout = () => {
      this.props.signOutAction();
    };

    resetTimeout = () => {
      this.clearTimeout();
      this.setTimeout();
    };

    componentWillUnmount() {
      this.clearTimeout();

      for (let i in this.events) {
        window.removeEventListener(this.events[i], this.resetTimeout);
      }
    }

    PropTypes = {
      router: PropTypes.object,
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps, { checkAuth, signOutAction })(Authentication);
}
