import axios from "axios";

import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";

import { manageError } from "./errors";

export const INVESTIGATION_JOINED = "INVESTIGATION_JOINED";

export const postResourceJoin =
  (token, investigationIdentifier, groupIdentifier) => async (dispatch) => {
    try {
      let uri;
      if (groupIdentifier) {
        uri = `${NEW_BACKEND_URL}/group/${groupIdentifier}/join`;
      } else if (investigationIdentifier) {
        uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/join`;
      }
      const payload = { token };
      const signingObj = signString("POST", uri);
      const res = await axios.post(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: INVESTIGATION_JOINED,
      });

      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
      return false;
    }
  };
