import React, { useState } from "react";
import classnames from "classnames";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  positiveButton: {
    height: "40px",
    display: "flex",
    textAlign: "center",
    marginBottom: "0px",
    backgroundColor: "#006ef5",
    textTransform: "none",
    color: "#fff",
    fontSize: "14px",
    lineHeight: "23px",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    "&:hover": {
      backgroundColor: "#2e86f2",
      color: "#fff",
    },
  },
  leftIcon: {
    marginRight: "15px",
    fontSize: "14px",
  },
  rightIcon: {
    marginLeft: "15px",
    fontSize: "14px",
  },
  loader: {
    position: "absolute",
    width: "16px",
    height: "16px",
  },
});

const TsPositiveButton = ({
  classes,
  onClick,
  text,
  disabled,
  loader,
  extras,
  icon,
  side,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisabled] = useState(false);

  const onClickMethod = (e) => {
    e.preventDefault();
    if (onClick !== undefined) {
      if (loader) {
        setDisabled(true);
        setLoading(true);
      }
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      className={classnames([classes.positiveButton, extras])}
      disabled={isDisable || disabled}
      onClick={(e) => onClickMethod(e)}
    >
      {icon !== undefined && side === "left" ? (
        <FontAwesomeIcon icon={icon} className={classes.leftIcon} />
      ) : null}
      {text}
      {loading && loader ? (
        <CircularProgress className={classes.loader} size={25} />
      ) : null}
      {icon !== undefined && side === "right" ? (
        <FontAwesomeIcon icon={icon} className={classes.rightIcon} />
      ) : null}
    </Button>
  );
};

export default withStyles(styles)(TsPositiveButton);
