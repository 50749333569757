import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInvestigationActivitiesAction } from "../../actions/activityMonitor";
import ActivityMonitor from "./ActivityMonitor";
import moment from "moment/moment";

export const ACTIVITY_PER_PAGE = 10;

const ActivityMonitorContainer = ({
  investigationIdentifier,
  getActivities,
  activitiesData,
}) => {
  const [activities, setActivitise] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [lastKey, setLastKey] = useState();
  const [lastKeyList, setLastKeyList] = useState();
  const [lastKeyCreatedAt, setLastKeyCreatedAt] = useState();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);

  useEffect(() => {
    const fetchActivities = async () => {
      await getActivities(investigationIdentifier, page);
    };
    if (investigationIdentifier) {
      fetchActivities();
    }
  }, [investigationIdentifier]);

  useEffect(() => {
    const fetchActivities = async (lastKey, lastKeyCreatedAt) => {
      await getActivities(investigationIdentifier, page);
    };
    if (investigationIdentifier && !firstRender) {
      fetchActivities(lastKey, lastKeyCreatedAt);
    }
  }, [lastKey, lastKeyCreatedAt]);

  useEffect(() => {
    if (activitiesData && activitiesData.length > 0) {
      setLastPage(false);
      setActivitise(activitiesData);
      if (activitiesData.length < ACTIVITY_PER_PAGE) {
        setLastPage(true);
      }
    } else {
      setLastPage(true);
      if (lastKeyList) {
        const list = Array.from(lastKeyList);
        list.pop();
        setLastKeyList(new Map(list));
        setPage(page - 1);
      }
    }
  }, [activitiesData]);

  const onPageChange = (directionFlg) => {
    let localPage = page;
    if (directionFlg) {
      localPage += 1;
      setPage(localPage);
    } else {
      if (localPage > 0) {
        localPage -= 1;
        setPage(localPage);
      }
    }

    if (localPage > 1 && directionFlg) {
      const lastActivity = activities[activities.length - 1];
      setLastKey(lastActivity.identifier);
      setLastKeyCreatedAt(moment(lastActivity.createdAt).valueOf());

      let map = lastKeyList;

      if (!map) {
        map = new Map();
      }
      map.set(localPage, {
        identifier: lastActivity.identifier,
        createdAt: moment(lastActivity.createdAt).valueOf(),
      });
      setLastKeyList(map);
    } else {
      const lastActivity = lastKeyList.get(localPage);
      if (localPage === 1) {
        setLastKey(undefined);
        setLastKeyCreatedAt(undefined);
      } else {
        setLastKey(lastActivity.identifier);
        setLastKeyCreatedAt(moment(lastActivity.createdAt).valueOf());
      }
    }
  };

  return (
    <ActivityMonitor
      activities={activities}
      handlePageChange={onPageChange}
      currentPage={page}
      lastPage={lastPage}
    />
  );
};

function mapStateToProps(state, ownProps) {
  const { dashboard, activities } = state;
  return {
    investigationIdentifier: dashboard.savedInvest,
    activitiesData: activities.activities,
  };
}

export default connect(mapStateToProps, {
  getActivities: getInvestigationActivitiesAction,
})(ActivityMonitorContainer);
