import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";
import qs from "qs";
import { GOT_SURVEYS } from "./surveyCreation";

const __ = window.async;

export const GOT_RESPONSES = "got_responses";
export const RESPONSES_ERROR = "responses_error";
export const GOT_FILTERED_RESPONSES = "got_filtered_responses";
export const GOT_RESPONSES_TOTAL_COUNT = "got_responses_total_count";
export const SET_RESPONSES_LAST_KEY_LIST = "SET_RESPONSES_LAST_KEY_LIST";
export const SET_CASES_LAST_KEY_LIST = "SET_CASES_LAST_KEY_LIST";
export const NO_MORE_RESPONSES = "NO_MORE_RESPONSES";

export function getResponsesAction(
  investigationId,
  status,
  page,
  lastKey,
  perPage,
  sortedBy,
  sortDirection,
  filterText,
  surveys,
  type
) {
  return async (dispatch) => {
    try {
      const queryParameters = qs.stringify({
        hidden: "all",
        page,
        lastKey,
        per_page: perPage,
        sorted_by: sortedBy,
        sort_direction: sortDirection,
        filter_text: filterText,
        type,
      });
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/responses?${queryParameters}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const responses = res.status === 204 ? [] : res.data.responses;
      // const { num_items: numItems = null } = res.data;

      let avatarObj = {};
      responses.forEach((e) => {
        if (e.avatar) {
          e.avatar = e.avatar.replace("/mediab64", "");
          avatarObj[e.avatar] = e.uri;
        }
      });

      //No reason to not run these parallel, although it probably saves very little.
      return new Promise((resolve, reject) =>
        __.parallel(
          [
            (callback) => {
              __.eachOf(
                avatarObj,
                (avatar, key, cb) => {
                  const tempUri = `${NEW_BACKEND_URL}${key}/base64`;
                  axios
                    .get(tempUri)
                    .then((response) => {
                      responses.forEach((e, i) => {
                        if (e.avatar === key) {
                          res.data.responses[i].avatarImg = response.data;
                        }
                      });
                      cb();
                    })
                    .catch((err) => {
                      if (err) {
                        console.error(err);
                        // dispatch(manageError(err));
                      }
                      cb();
                    });
                },
                (err) => {
                  if (err) {
                    callback(err);
                  } else {
                    callback();
                  }
                }
              );
            },
            (callback) => {
              if (surveys && surveys.length > 0) {
                responses.forEach((e, i) => {
                  let survey = surveys.find((ele) => {
                    return ele.hexIdentifier === e.survey;
                  });
                  responses[i].surveyTitle = survey ? survey.title : "";
                });
                callback();
              } else {
                const surveyUri = `${NEW_BACKEND_URL}/investigation/${investigationId}/surveys`;
                const signingObj = signString("GET", surveyUri);
                axios
                  .get(surveyUri, {
                    headers: {
                      "X-Date": signingObj.date,
                      Authorization: signingObj.string,
                    },
                  })
                  .then((surveyRes) => {
                    responses.forEach((e, i) => {
                      let survey = surveyRes.data.surveys.find((ele) => {
                        return ele.hexIdentifier === e.survey;
                      });

                      responses[i].surveyTitle = survey ? survey.title : "";
                    });
                    callback();
                  })
                  .catch((err) => {
                    callback(err);
                  });
              }
            },
          ],
          (err) => {
            if (err) {
              dispatch(manageError(err));
              reject(err);
            } else {
              if (responses.length > 0) {
                dispatch({
                  type: GOT_RESPONSES,
                  payload: responses,
                });
                let filteredByStatus = responses.filter((e, i, arr) => {
                  return e.status === status;
                });
                dispatch({
                  type: GOT_FILTERED_RESPONSES,
                  payload: filteredByStatus,
                });
                if (responses.length < perPage) {
                  dispatch({
                    type: NO_MORE_RESPONSES,
                    payload: true,
                  });
                }
              } else if (type === "archive") {
                dispatch({
                  type: GOT_RESPONSES,
                  payload: responses,
                });
              } else {
                if (page === 0 || page === 1) {
                  dispatch({
                    type: GOT_RESPONSES,
                    payload: responses,
                  });
                  dispatch({
                    type: GOT_FILTERED_RESPONSES,
                    payload: responses,
                  });
                }
                dispatch({
                  type: NO_MORE_RESPONSES,
                  payload: true,
                });
              }
              resolve();
            }
          }
        )
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: RESPONSES_ERROR,
        payload:
          "Error retrieving entries. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function setLastKeyList(lastKeyList) {
  return (dispatch) => {
    dispatch({
      type: SET_RESPONSES_LAST_KEY_LIST,
      payload: lastKeyList,
    });
  };
}

export function setLastKeyListForCases(lastKeyList) {
  return (dispatch) => {
    dispatch({
      type: SET_CASES_LAST_KEY_LIST,
      payload: lastKeyList,
    });
  };
}

export function changeShowStatusAction(responses, status) {
  return async (dispatch) => {
    let filteredByStatus = responses.filter((e, i, arr) => {
      return e.status === status;
    });
    dispatch({
      type: GOT_FILTERED_RESPONSES,
      payload: filteredByStatus,
    });
  };
}

export function changeResponseStatusAction(responses, toChange, status) {
  return (dispatch) => {
    const newResponses = [...responses];
    toChange.forEach((e, i, arr) => {
      const index = newResponses.findIndex((element) => {
        return element.b64Identifier === e.identifier;
      });
      newResponses[index].status = status;
    });
    dispatch({
      type: GOT_RESPONSES,
      payload: newResponses,
    });
    dispatch(
      changeShowStatusAction(
        newResponses,
        status === "active" ? "archive" : "active"
      )
    );
  };
}

export function postArchiveItems(investigationIdentifier, items, status) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/archive-items`;
    const payload = { ...items, status };
    const signingObj = signString("POST", uri);
    try {
      const response = await axios.post(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: RESPONSES_ERROR,
        payload: error.message,
      });
      dispatch(manageError(error));
      return error;
    }
  };
}

export function postLockItems(investigationIdentifier, items, status) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/lock-items`;
    const payload = { ...items, status };
    const signingObj = signString("POST", uri);
    const response = await axios.post(uri, payload, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });
    return response.data;
  };
}

export function postDeleteItems(investigationIdentifier, items) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/delete-items`;
    /*
    let responses = [];
    newResponses.forEach((e, i, arr) => {
      const tempObj = {};
      tempObj.identifier = e.hexIdentifier;
      tempObj.revisionIdentifier = e.revisionIdentifier;

      responses.push(tempObj);
    });
    */
    const payload = { ...items };
    const signingObj = signString("POST", uri);
    const response = await axios.post(uri, payload, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });
    return response.data;
  };
}

export const TOGGLE_GROUP_BY_CASE = "TOGGLE_GROUP_BY_CASE";
export const toggleGroupByCase = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_GROUP_BY_CASE,
    payload: value,
  });
};
