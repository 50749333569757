import { GOT_SURVEYS } from "../actions/surveyCreation";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_SURVEYS:
      return action.payload;
    default:
      return state;
  }
}
