import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "es"];

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    es: { translation: translationES },
  },
  fallbackLng,
  detection: {
    checkWhitelist: true,
  },
  debug: false,
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false, // no need for react. it escapes by default
  },
});

export default i18n;
