import {
  GOT_INVESTIGATIONS,
  INVESTIGATIONS_ERROR,
  SAVED_INVESTIGATION,
  SAVED_TITLE,
  GOT_PERMISSIONS,
  PERMISSIONS_ERROR,
  INVESTIGATION_LOOKUP,
  GOT_AVATAR,
  AVATAR_ERROR,
  GOT_INVEST_AVATAR,
  INVEST_AVATAR_ERROR,
  SAVED_INVEST_DETAILS,
} from "../actions/dashboard";
import { GOT_INVEST_DETAILS } from "../actions/investManagement";

import { reset } from "redux-form";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_INVESTIGATIONS:
      return {
        ...state,
        investsData: action.payload,
        investsError: undefined,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case INVESTIGATIONS_ERROR:
      return {
        ...state,
        investsError: action.payload,
      };
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case SAVED_TITLE:
      return {
        ...state,
        savedInvestTitle: action.payload,
      };
    case GOT_INVEST_DETAILS:
    case SAVED_INVEST_DETAILS:
      return {
        ...state,
        investDetails: action.payload,
      };
    case GOT_PERMISSIONS:
      return {
        ...state,
        savedInvestPerms: action.payload,
      };
    case PERMISSIONS_ERROR:
      return {
        ...state,
        investPermsError: action.payload,
      };
    case GOT_AVATAR:
      return {
        ...state,
        userAvatar: action.payload,
      };
    case AVATAR_ERROR:
      return {
        ...state,
        userAvatarError: "Error retreiving user avatar.",
      };
    case GOT_INVEST_AVATAR:
      return {
        ...state,
        investAvatar: action.payload,
      };
    case INVEST_AVATAR_ERROR:
      return {
        ...state,
        investAvatarError: "Error retrieving invest avatar.",
      };
  }
  return state;
}
