import { TOGGLE_GROUP_BY_CASE } from "../actions/data";

const initialState = true;

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_GROUP_BY_CASE:
      return action.payload;
    default:
      break;
  }
  return state;
}
