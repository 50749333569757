import React from "react";
import classnames from "classnames";

import { withStyles } from "@material-ui/core/styles";
// import { TextField } from "redux-form-material-ui";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  focused: {},
  outlinedInput: {
    borderRadius: 4,
    width: "100%",
    // height: "38px",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    color: "#272a2e",
    verticalAlign: "center",
    "&$focused $notchedOutline": {
      border: "1px solid #006ef5",
      boxShadow: "0 0 0 4px #bfdcff",
    },
    "&:hover > fieldset": {
      border: "1px solid #006ef5 !important",
    },
  },
  notchedOutline: {},
  input: {
    padding: "11px 14px",
  },
  multiline: {
    padding: "0 24px 0 0",
  },
  error: {
    borderColor: "#ea3546",
  },
});

const TsTextField = ({
  classes,
  placeholder,
  errorMsg,
  enteredText,
  valueChange,
  change,
  autoFocus,
  errors,
  ...props
}) => (
  <TextField
    placeholder={placeholder}
    variant="outlined"
    InputLabelProps={{ shrink: false }}
    className={classnames(classes.rootNoOutline, {
      [classes.error]: false,
    })}
    InputProps={{
      classes: {
        root: classnames(classes.outlinedInput, classes.root),
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        input: classes.input,
        multiline: classes.multiline,
      },
    }}
    value={enteredText || ""}
    onChange={change}
    error={(valueChange && enteredText === "") || errors}
    helperText={valueChange && enteredText === "" ? errorMsg : ""}
    fullWidth={true}
    autoFocus={autoFocus}
    {...props}
  />
);

export default withStyles(styles)(TsTextField);
