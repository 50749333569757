export const GOT_ERROR = "got_error";
export const RESET_ERROR = "reset_error";

export function manageError(error) {
  return (dispatch) => {
    console.error("MANAGE", error.response || error.message);
    dispatch({
      type: GOT_ERROR,
      payload: error,
    });
  };
}

export function resetErrors(error) {
  return (dispatch) => {
    dispatch({
      type: RESET_ERROR,
    });
  };
}
