import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { parse } from "qs";

import { verifyEmail } from "../actions/signIn";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import { postResourceJoin } from "../actions/joinResource";

const EmailVerificationContainer = ({
  verifyEmail,
  history,
  location,
  userIdentifier,
  token,
  inviteToken,
  investigationIdentifier,
  groupIdentifier,
}) => {
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!userIdentifier || !token) {
      return;
    }
    verifyEmail(userIdentifier, token)
      .then((response) => {
        if (inviteToken) {
          return Promise.all([
            response,
            postResourceJoin(
              inviteToken,
              investigationIdentifier,
              groupIdentifier
            ),
          ]);
        } else {
          return Promise.resolve(response);
        }
      })
      .then((response) => {
        const { requiresOnboarding } = response;
        if (requiresOnboarding) {
          history.push("/onboarding-survey");
        } else {
          history.push("/dash/data");
        }
      });
  }, [userIdentifier, token]);

  if (!userIdentifier || !token) {
    // return (
    //   <ForgotPasswordRequestForm
    //     submitted={submitted}
    //     onSubmit={handleRequestFormSubmit}
    //   />
    // );
  } else {
    return <VerifyEmail />;
  }
};

const mapStateToProps = (state, ownProps) => {
  const { userIdentifier, token } = parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });
  return {
    userIdentifier,
    token,
  };
};

export default connect(mapStateToProps, {
  verifyEmail,
  postResourceJoin,
})(EmailVerificationContainer);
