import {
  INVESTIGATION_EXPORT_REQUESTED,
  INVESTIGATION_EXPORT_CREATED,
} from "../actions/export";

const initialState = {};

export default function (state = initialState, action) {
  let identifier;
  switch (action.type) {
    case INVESTIGATION_EXPORT_REQUESTED:
      ({ identifier } = action.payload);
      return { ...state, [identifier]: true };
    case INVESTIGATION_EXPORT_CREATED:
      ({ identifier } = action.payload);
      return { ...state, [identifier]: false };
    default:
      return state;
  }
}
