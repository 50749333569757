import {
  GOT_RESPONSES,
  GOT_FILTERED_RESPONSES,
  SET_RESPONSES_LAST_KEY_LIST,
  SET_CASES_LAST_KEY_LIST,
  NO_MORE_RESPONSES,
} from "../actions/data";
import {
  SAVED_INVESTIGATION,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";

const initialState = {};

export default function (state = initialState, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case GOT_RESPONSES:
      return {
        ...state,
        responseData: action.payload,
        noMoreResponses: false,
      };
    case GOT_FILTERED_RESPONSES:
      return {
        ...state,
        filteredResponses: action.payload,
      };
    case SET_RESPONSES_LAST_KEY_LIST:
      return {
        ...state,
        lastKeyList: action.payload,
      };
    case SET_CASES_LAST_KEY_LIST:
      return {
        ...state,
        lastKeyListForCases: action.payload,
      };
    case NO_MORE_RESPONSES:
      return {
        ...state,
        noMoreResponses: action.payload,
      };
  }
  return state;
}
