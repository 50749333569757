import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { parse } from "qs";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { CircularProgress, withStyles } from "@material-ui/core";

import {
  getInvestigationsAction,
  saveInvestigationAction,
} from "../actions/dashboard";
import { getCases } from "../actions/cases";
import { getResponsesAction } from "../actions/data";
import { getSurveysAction } from "../actions/surveyCreation";
import { getInvestDetails } from "../actions/investManagement";

const EConsent = ({
  classes,
  getInvestigationsAction,
  getCases,
  getResponsesAction,
  getSurveysAction,
  saveInvestigation,
  investigationIdentifier,
  surveyIdentifier,
  caseIdentifier,
  responseIdentifier,
  investigations,
  investigation,
  investDetails,
  getInvestDetails,
  cases,
  history,
}) => {
  const [selectedInvestigation, UNSAFE_setSelectedInvestigation] =
    useState(null);

  const setSelectedInvestigation = (nextValue) => {
    localStorage.setItem("investigation", nextValue);
    UNSAFE_setSelectedInvestigation(nextValue);
  };

  useEffect(() => {
    if (!investigations) {
      getInvestigationsAction();
    }
  }, []);

  useEffect(() => {
    if (!investigations) {
      return;
    }
    let selectedInvestigation = "";
    if (investigations && investigations.length > 0) {
      const [newInvest] = investigations.filter(
        (invest) => invest.uri.split("/")[1] === investigationIdentifier
      );

      // const [investigation] = investigations;
      const [, identifier] = newInvest.uri.split("/");
      const { title } = newInvest;
      selectedInvestigation = `${identifier}|${title}`;
    } else {
      selectedInvestigation = "";
    }
    // saveInvestDetails(investigations, selectedInvestigation);
    setSelectedInvestigation(selectedInvestigation);
  }, [investigations]);

  useEffect(() => {
    if (!selectedInvestigation) {
      return;
    }
    saveInvestigation(selectedInvestigation);
  }, [selectedInvestigation]);

  const handleLongitudinalFlag = (investigationId) => {
    // if (investDetails === undefined) {
    //   return;
    // }

    const [newInvest] = investigations.filter(
      (invest) => invest.uri.split("/")[1] === investigationIdentifier
    );

    console.log(`investDetails: ${JSON.stringify(newInvest)}`);
    if (!newInvest.longitudinal) {
      getResponsesAction(investigationId, "active");
    } else if (newInvest.longitudinal) {
      getCases(investigationId);
    }
  };

  useEffect(() => {
    if (!investigation) {
      return;
    }
    Promise.all([
      // getInvestigationPermissions(investigation),
      // getInvestigationBilling(investigation),
      // getInvestigationViews(investigation),
      getSurveysAction(investigation),
    ]);
    getInvestDetails(investigation).then(() => {
      handleLongitudinalFlag(investigation);
    });
  }, [investigation]);

  useEffect(() => {
    if (!cases) {
      return;
    }

    const [case_] = cases.filter(
      (case_) => case_.identifier === caseIdentifier
    );

    getResponsesAction(investigationIdentifier, "active").then(() => {
      history.push({
        pathname: `/forms/${surveyIdentifier}`,
        state: {
          responseIdentifier: responseIdentifier,
        },
      });
    });
  }, [cases]);

  return (
    <div>
      <CircularProgress size={40} />
      Verifying link
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    investigationIdentifier,
    surveyIdentifier,
    caseIdentifier,
    responseIdentifier,
  } = parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });
  return {
    investigationIdentifier,
    surveyIdentifier,
    caseIdentifier,
    responseIdentifier,
    investigations: state.dashboard.investsData,
    investigation: state.dashboard.savedInvest,
    investDetails: state.dashboard.investDetails,
    cases: state.caseData.cases,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getInvestigationsAction,
    getCases,
    getResponsesAction,
    getSurveysAction,
    getInvestDetails,
    saveInvestigation: saveInvestigationAction,
  })
)(EConsent);
