import { CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const VerifyEmail = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress size={40} className={classes.progress} />
      Verifying your email
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    display: "inline-block",
    marginRight: 16,
  },
};

export default withStyles(styles)(VerifyEmail);
