import { GOT_INVESTIGATION_BILLING } from "../actions/dashboard";

export default function (state = {}, action) {
  switch (action.type) {
    case GOT_INVESTIGATION_BILLING:
      const { provisionedUntil: provisionedUntilString } = action.payload;
      const provisionedUntil = new Date(provisionedUntilString);
      const now = new Date();
      const validProvision = now < provisionedUntil;
      return {
        validProvision,
        ...action.payload,
      };
    default:
      break;
  }
  return state;
}
