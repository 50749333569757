import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TsIconButton from "./shared/TsIconButton";
import { faRedo } from "@fortawesome/pro-solid-svg-icons";

const ErrorPage = ({ classes }) => {
  return (
    <div className={classes.body}>
      <div className={classes.error}>
        <img
          src="https://uploads-ssl.webflow.com/5fb6849ed98f565962345f5c/61fbfa7d3549d767eb24fec2_giphy%20(2).gif"
          alt=""
          className={classes.image}
        />
        <h2 className={classes.h2}>
          We are very sorry, something is wrong 🙁
          <br />
        </h2>
        <p class={classes.para}>
          This issue probably has an easy fix. <br />
          <br />
          Please contact us so we can help you out. <br />
          <br />
          Email:
          <a href="mailto:support@yuzulabs.com" target="_blank">
            <strong className={classes.email}> support@yuzulabs.com</strong>
            <br />
            <br />
          </a>
        </p>
        <TsIconButton
          icon={faRedo}
          onClick={() => {
            window.location.reload();
          }}
          text="Reload page"
          noMargin
          style={{
            fontSize: "16px",
            padding: "12px, 16px",
          }}
        />
      </div>
    </div>
  );
};

const styles = (theme) => ({
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    minHeight: "100%",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "14px",
    color: "#272a2e",
    lineHeight: "20px",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
  },
  image: {
    width: "140px",
    marginBottom: "32px",
    borderRadius: "8px",
    maxWidth: "100%",
    verticalAlign: "middle",
    display: "inline-block",
  },
  h2: {
    marginTop: "0px",
    marginBottom: "8px",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  para: {
    marginTop: "32px",
    marginBottom: "32px",
    fontSize: "20px",
  },
  email: {
    color: "#006ef5",
    textDecoration: "none",
    fontWeight: 600,
  },
  reload: {
    fontSize: "16px",
  },
});

export default withStyles(styles)(ErrorPage);
