import { GOT_RESPONSES_TOTAL_COUNT } from "../actions/data";

const initialState = 0;

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_RESPONSES_TOTAL_COUNT:
      return action.payload;
    default:
      return state;
  }
}
