import { getInvestigationAvatar, saveInvestigationAction } from "./dashboard";
import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");

export const UPDATED_INVEST = "updated_invest";
export const UPDATED_INVEST_ERROR = "updated_invest_error";
export const UPDATED_INVEST_AVATAR = "updated_invest_avatar";
export const UPDATED_INVEST_AVATAR_ERROR = "updated_invest_avatar_error";
export const GOT_INVEST_DETAILS = "got_invest_details";
export const INVEST_DETAILS_ERROR = "invest_details_error";

export function getInvestDetails(invest) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${invest}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const payload = {
        uri: `investigation/${invest}`,
        ...res.data.details,
      };
      try {
        const { longitudinal } = res.data.details;
        window.userGuiding.track("segment", {
          longitudinal,
          investigationIdentifier: invest,
        });
      } catch (e) {
        console.error(`error`, e);
      }
      dispatch(getInvestigationAvatar(res.data.details.avatar));
      return dispatch({
        type: GOT_INVEST_DETAILS,
        payload,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVEST_DETAILS_ERROR,
        payload:
          "Error getting investigation details. Please try again, or contact an administrator.",
      });
      return dispatch(manageError(error));
    }
  };
}

export function patchInvestDetails(values, invest) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${invest}/details`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(`${uri}`, values, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      console.log(res);

      dispatch({
        type: UPDATED_INVEST,
      });
      if (values.title) {
        dispatch(saveInvestigationAction(invest + "|" + values.title));
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATED_INVEST_ERROR,
        payload:
          "Error updating investigation details. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function patchAvatar(values, invest) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${invest}/details`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(`${uri}`, values, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch(getInvestigationAvatar(res.data.avatarUrl));

      dispatch({
        type: UPDATED_INVEST_AVATAR,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATED_INVEST_AVATAR_ERROR,
        payload:
          "Error updating user details. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export const GOT_INVEST_LINK = "GOT_INVEST_LINK";
export const INVEST_LINK_ERROR = "INVEST_LINK_ERROR";
export const getInvestigationLink = (investigationId) => async (dispatch) => {
  try {
    const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/link`;

    const signingObj = signString("GET", uri);
    const res = await axios.get(`${uri}`, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });

    const payload = res.status === 200 ? res.data : {};

    dispatch({
      type: GOT_INVEST_LINK,
      payload,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: INVEST_LINK_ERROR,
      payload:
        "Error retrieving investigation link. Please try again, or contact an administrator.",
    });
    dispatch(manageError(error));
  }
};

export const PATCHED_INVEST_LINK = "PATCHED_INVEST_LINK";
export const patchInvestigationLink =
  (investigationId, permissions, resetLink) => async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/link`;

      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(
        `${uri}`,
        { permissions, resetLink: !!resetLink },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );

      dispatch({
        type: PATCHED_INVEST_LINK,
      });

      return res;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVEST_LINK_ERROR,
        payload:
          "Error patching investigation link. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };

export const DELETED_INVEST_LINK = "DELETED_INVEST_LINK";
export const deleteInvestigationLink =
  (investigationId) => async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/link`;

      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: DELETED_INVEST_LINK,
      });

      return res;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVEST_LINK_ERROR,
        payload:
          "Error deleting investigation link. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };

export const POSTED_INVEST_LINK = "POSTED_INVEST_LINK";
export const postInvestigationLink =
  (investigationId, permissions) => async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/link`;

      const signingObj = signString("POST", uri);
      const res = await axios.post(
        `${uri}`,
        { permissions },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );

      dispatch({
        type: POSTED_INVEST_LINK,
      });

      return res;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVEST_LINK_ERROR,
        payload:
          "Error posting investigation link. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };

export const GOT_INVESTIGATION_WEBHOOKS = "GOT_INVESTIGATION_WEBHOOKS";
export const INVESTIGATION_WEBHOOKS_ERROR = "INVESTIGATION_WEBHOOKS_ERROR";
export function getInvestigationWebhooks(investigationIdentifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/webhooks`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const payload = res.data;
      dispatch({
        type: GOT_INVESTIGATION_WEBHOOKS,
        payload,
      });
      return payload;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATION_WEBHOOKS_ERROR,
        payload:
          "Error getting investigation webhooks. Please try again, or contact an administrator.",
      });
      return dispatch(manageError(error));
    }
  };
}

const UPDATED_INVESTIGATION_WEBHOOKS = "UPDATED_INVESTIGATION_WEBHOOKS";
export function patchInvestigationWebhooks(investigationIdentifier, webhooks) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/webhooks`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(
        uri,
        { webhooks },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );

      const payload = res.data;
      dispatch({
        type: UPDATED_INVESTIGATION_WEBHOOKS,
        payload,
      });
      return payload;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATION_WEBHOOKS_ERROR,
        payload:
          "Error updating investigation webhooks. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function generateInvestigationApiKey(
  investigationIdentifier,
  expiration
) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/apiKey`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(
        uri,
        {
          expirationInMonths:
            expiration == 1 || expiration == 3 || expiration == 6
              ? parseInt(expiration)
              : undefined,
        },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );

      const payload = res.data;
      return payload;
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATION_WEBHOOKS_ERROR,
        payload:
          "Error updating investigation webhooks. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getStudyExportFile(studyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/study/${studyId}/export`;
      let headers = {};
      const signingObj = signString("GET", uri);
      headers = {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      };
      const res = await axios({
        url: uri,
        method: "GET",
        responseType: "blob",
        headers,
      });
      const filename = decodeURIComponent(
        res.headers["content-disposition"].replace("attachment; filename=", "")
      );
      const filetype = res.headers["content-type"];
      const blob = new Blob([res.data]);
      const file = new File([blob], filename, { type: filetype });
      const url = window.URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function validateStudyImport(studyId, file) {
  return async (dispatch) => {
    try {
      const url = `${NEW_BACKEND_URL}/study/${studyId}/import-diff`;
      const form = new FormData();

      form.append("file", file);

      const signingObj = signString("POST", url);
      const res = await axios.post(url, form, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return res.data;
    } catch (error) {
      dispatch(manageError(error));
    }
  };
}

export function importStudy(studyId, summaryData) {
  return async (dispatch) => {
    try {
      const url = `${NEW_BACKEND_URL}/study/${studyId}/import`;

      const signingObj = signString("PATCH", url);
      await axios.patch(url, summaryData, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return;
    } catch (error) {
      dispatch(manageError(error));
    }
  };
}
