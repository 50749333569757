import { GOT_INVEST_LINK } from "../actions/investManagement";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_INVEST_LINK:
      return action.payload;
    default:
      break;
  }
  return state;
}
