import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { parse } from "qs";

import {
  signInAction,
  resendEmailVerificationLink,
} from "../../actions/signIn";
import OAuthStudyPages from "./OAuthStudyPages";
import OAuthAccessDenied from "./OAuthAccessDenied";

export const ACTION_STUDY_REDIRECT = "study-redirect";
export const ACTION_MOBILE_LOGIN = "mobile-redirect";

const OAuthStudyPagesContainer = ({
  code,
  error,
  token,
  groupIdentifier,
  signInAction,
  deepLink,
  investigation,
  action,
  actionData,
  history,
}) => {
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (!code) {
      return;
    }
    signInAction({
      code,
      token,
      groupIdentifier,
    }).then((response) => {
      if (response.location) {
        history.push(response.location);
        return;
      }

      switch (action) {
        case ACTION_STUDY_REDIRECT:
          let url = `/investigation/${actionData.investigationIdentifier}?caseId=${actionData.caseIdentifier}`;
          if (actionData.formIdentifier) {
            url += `&formId=${actionData.formIdentifier}`;
          }
          if (actionData.entryIdentifier) {
            url += `&entryId=${actionData.entryIdentifier}`;
          }
          if (actionData.mode) {
            url += `&mode=${actionData.mode}`;
          }
          if (actionData.redirectUrl) {
            url += `&redirectUrl=${actionData.redirectUrl}`;
          }
          if (actionData.autoRedirect) {
            url += `&autoRedirect=${actionData.autoRedirect}`;
          }
          if (actionData.lang) {
            url += `&lang=${actionData.lang}`;
          }
          history.replace(url);
          return;

        case ACTION_MOBILE_LOGIN:
          history.replace(`/mobile/login`);
          return;

        default:
          break;
      }
      const { requiresOnboarding } = response;
      if (deepLink) {
        window.location.replace(deepLink);
      } else if (requiresOnboarding) {
        setTimeout(() => {
          history.push("/onboarding-survey");
        }, 1);
      } else {
        history.push("/dash/data", investigation);
      }
    });
  }, [code]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setShowError(true);
  }, [error]);

  const handleBackToLogin = () => {
    history.push("/login");
  };

  return showError ? (
    <OAuthAccessDenied onLoginClick={handleBackToLogin} />
  ) : (
    <OAuthStudyPages />
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    code,
    error,
    state: stateData,
  } = parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });
  let token;
  let groupIdentifier;
  let deepLink;
  let investigation;
  let action;
  let actionData;
  if (stateData) {
    try {
      const stateObject = JSON.parse(stateData);
      action = stateObject.action;

      if (action === ACTION_STUDY_REDIRECT) {
        actionData = {
          ...stateObject,
        };
      }

      token = stateObject.token;
      groupIdentifier = stateObject.groupIdentifier;
      if (stateObject.investigationIdentifier) {
        investigation = {
          investigationIdentifier: stateObject.investigationIdentifier,
          investigationTitle: stateObject.investigationTitle,
        };
      }
    } catch (error) {
      if (
        stateData &&
        typeof stateData === "string" &&
        stateData.includes("https://teamscope")
      ) {
        deepLink = stateData;
      }
    }
  }
  return {
    code,
    error,
    token,
    groupIdentifier,
    deepLink,
    investigation,
    action,
    actionData,
  };
};

export default connect(mapStateToProps, {
  signInAction,
  resendEmailVerificationLink,
})(OAuthStudyPagesContainer);
