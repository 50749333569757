import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";

import axios from "axios";

export const RESPONSE_CREATED = "response_created";
export const RESPONSE_CREATION_ERROR = "response_creation_error";

const unscramble = (scrambled) => {
  const buffer = Buffer.from(scrambled, "hex");
  const unscrambling = [0, 6, 6, 8];
  let i, _i, _ref;
  const t0 = unscrambling[0],
    t1 = unscrambling[1],
    r0 = unscrambling[2],
    r1 = unscrambling[3];
  const dr = r1 - r0;
  for (
    i = _i = 0, _ref = t1 - t0;
    0 <= _ref ? _i < _ref : _i > _ref;
    i = 0 <= _ref ? ++_i : --_i
  ) {
    buffer[t0 + i] = (0x100 + buffer[t0 + i] - buffer[r0 + (i % dr)]) % 0x100;
  }
  return buffer.toString("hex");
};

export const reverse = (s) => {
  let o = "";
  for (let i = s.length - 1; i >= 0; i--) o += s[i];
  return o;
};

export const createResponseIdentifier = (surveyIdentifier) => {
  const millisecondsTimestamp = Date.now();
  let hexTimestamp = millisecondsTimestamp.toString(16);
  hexTimestamp = reverse(hexTimestamp);
  hexTimestamp = hexTimestamp.padEnd(6 * 2, "0");
  hexTimestamp = reverse(hexTimestamp);

  const unscrambledSurveyIdentifier = unscramble(surveyIdentifier);

  const responseIdentifier =
    hexTimestamp + unscrambledSurveyIdentifier + "0000";
  return responseIdentifier;
};

export function postResponse(items, surveyIdentifier, caseIdentifier) {
  const responseIdentifier = createResponseIdentifier(surveyIdentifier);
  const responseUri = "response/" + responseIdentifier;
  let payload = {
    uri: responseUri,
    items,
  };
  if (caseIdentifier) {
    payload = { ...payload, caseUri: `case/${caseIdentifier}` };
  }
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/response`;
    const signingObj = signString("POST", uri);
    return axios
      .post(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      })
      .then((res) => {
        const payload = res.data;
        dispatch({
          type: RESPONSE_CREATED,
          payload: payload,
        });
        window.userGuiding &&
          window.userGuiding.track("segment", {
            responseCreated: true,
          });
        return res;
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: RESPONSE_CREATION_ERROR,
          payload:
            "Error creating response. Please try again, or contact an administrator.",
        });
        dispatch(manageError(error));
        throw error;
      });
  };
}

export function postAnonymousResponse(items, surveyIdentifier, caseIdentifier) {
  const responseIdentifier = createResponseIdentifier(surveyIdentifier);
  const responseUri = "response/" + responseIdentifier;
  let payload = {
    uri: responseUri,
    items,
  };
  if (caseIdentifier) {
    payload = { ...payload, caseUri: `case/${caseIdentifier}` };
  }
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/response`;
    return axios
      .post(uri, payload)
      .then((res) => {
        const payload = res.data;
        dispatch({
          type: RESPONSE_CREATED,
          payload: payload,
        });
        return res;
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: RESPONSE_CREATION_ERROR,
          payload:
            "Error creating response. Please try again, or contact an administrator.",
        });
        dispatch(manageError(error));
        throw error;
      });
  };
}

export const GOT_RESPONSE = "got_response";
export const RESPONSE_ERROR = "response_error";

export function getResponse(responseId) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/response/${responseId}`;
    const signingObj = signString("GET", uri);
    return axios
      .get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      })
      .then((res) => {
        const payload = res.data;
        dispatch({
          type: GOT_RESPONSE,
          payload,
        });
        return payload;
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: RESPONSE_ERROR,
          payload:
            "Error getting response. Please try again, or contact an administrator.",
        });
        dispatch(manageError(error));
        throw error;
      });
  };
}

export const RESPONSE_PATCHED = "response_patched";
export const RESPONSE_MODIFICATION_ERROR = "response_modification_error";

export function patchResponse(items, responseId, reasons, caseUri) {
  const revisionDate = Math.round(Date.now() / 1000);
  let payload;
  if (caseUri !== undefined) {
    payload = {
      caseUri,
      revisionDate,
      items,
    };
  } else {
    payload = {
      items,
      reasons,
      revisionDate,
    };
  }
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}/response/${responseId}`;
    const signingObj = signString("PATCH", uri);
    return axios
      .patch(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      })
      .then((res) => {
        dispatch({
          type: RESPONSE_PATCHED,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: RESPONSE_MODIFICATION_ERROR,
          payload:
            "Error modifying response. Please try again, or contact an administrator.",
        });
        console.log(`eero`, error.message);
        dispatch(manageError(error));
        // it will still crash the app, but will let user know the error first
        throw error;
      });
  };
}
