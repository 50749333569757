import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { reverse } from "./responses";
import { NEW_BACKEND_URL } from "../middleware/networking";

import { GOT_RESPONSES_TOTAL_COUNT } from "./data";

import axios from "axios";
import qs from "qs";

export const GOT_CASES = "got_cases";
export const CASES_ERROR = "cases_error";
export const NO_MORE_CASES = "NO_MORE_CASES";
export const getCases = (
  investigationId,
  mode,
  page,
  perPage,
  lastKey,
  type,
  particularCase,
  casesFilterText
) => {
  return async (dispatch) => {
    try {
      let queryParameters;
      if (mode && page && perPage) {
        const queryObject = {
          mode,
          limit: perPage,
          page: page - 1,
        }
        if (lastKey !== "" && lastKey !== undefined) {
          queryObject.caseIdentifier = lastKey
        }
        if (type) {
          queryObject.type = type
        }
        if (casesFilterText !== "" && casesFilterText !== undefined) {
          queryObject.search = casesFilterText
        }
        queryParameters = qs.stringify(queryObject);
      } else {
        const queryObject = {}
        if (type) {
          queryObject.type = type
        }
        if (particularCase) {
          queryObject.caseId = particularCase
        }
        if (Object.keys(queryObject).length > 0) {
          queryParameters = qs.stringify(queryObject);
        }
      }

      let uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/cases`;
      if (queryParameters) {
        uri += `?${queryParameters}`
      }
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      let payload = {
        cases: res.data.cases.map(
          ({
            identifier,
            uri,
            creation,
            label,
            hidden,
            revisionIdentifier,
            ...rest
          }) => ({
            ...rest,
            identifier,
            uri,
            hidden,
            revisionIdentifier,
            creationDate: creation,
            caseLabel: label,
          })
        ),
        count: res.data.count ? res.data.count : 0,
      };

      dispatch({
        type: GOT_CASES,
        payload,
      });
      if (payload.cases.length < perPage) {
        dispatch({
          type: NO_MORE_CASES,
          payload: true,
        });
      }
    } catch (error) {
      dispatch({
        type: CASES_ERROR,
        payload:
          "Error retrieving cases. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};

export const GOT_CASE_RESPONSES = "got_case_responses";
export const CASE_RESPONSES_ERROR = "case_responses_error";
export const SET_CASE_RESPONSES_LAST_KEY_LIST =
  "SET_CASE_RESPONSES_LAST_KEY_LIST";
export const NO_MORE_CASE_RESPONSES = "NO_MORE_CASE_RESPONSES";
export const getCaseResponses = (
  investigationId,
  caseId,
  page,
  lastKey,
  perPage,
  sortedBy,
  sortDirection,
  type
) => {
  return async (dispatch) => {
    try {
      const queryParameters = qs.stringify({
        caseIdentifier: caseId,
        page,
        lastKey,
        per_page: perPage,
        sorted_by: sortedBy,
        sort_direction: sortDirection,
        type,
      });
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/responses?${queryParameters}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const responses = res.status === 204 ? [] : res.data.responses;
      const { num_items: numItems = null } = res.data;
      const payload = responses.map(
        ({
          identifier,
          survey,
          creation,
          revisionUNIX,
          name,
          avatar,
          status,
          lockedAt,
          items,
        }) => ({
          identifier,
          surveyId: survey,
          creationDate: creation,
          createdBy: name,
          editionDate: revisionUNIX,
          editorAvatar:
            avatar && `${NEW_BACKEND_URL}${avatar.replace("/mediab64", "")}`,
          status,
          lockedAt,
          items,
        })
      );
      if (payload.length > 0) {
        dispatch({
          type: GOT_CASE_RESPONSES,
          payload,
        });
        if (payload.length < perPage) {
          dispatch({
            type: NO_MORE_CASE_RESPONSES,
            payload: true,
          });
        }
      } else {
        if (page === 0 || page === 1) {
          dispatch({
            type: GOT_CASE_RESPONSES,
            payload: responses,
          });
        }
        dispatch({
          type: NO_MORE_CASE_RESPONSES,
          payload: true,
        });
      }
      // dispatch({
      //   type: GOT_RESPONSES_TOTAL_COUNT,
      //   payload: numItems,
      // });
    } catch (error) {
      dispatch({
        type: CASE_RESPONSES_ERROR,
        payload:
          "Error retrieving case responses. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};

export function setCaseResponsesLastKeyList(lastKeyList) {
  return (dispatch) => {
    dispatch({
      type: SET_CASE_RESPONSES_LAST_KEY_LIST,
      payload: lastKeyList,
    });
  };
}

export const createCaseIdentifier = () => {
  const millisecondsTimestamp = Date.now();
  let hexTimestamp = millisecondsTimestamp.toString(16);
  hexTimestamp = reverse(hexTimestamp);
  hexTimestamp = hexTimestamp.padEnd(6 * 2, "0");
  hexTimestamp = reverse(hexTimestamp);

  const caseIdentifier = hexTimestamp + "0000";
  return caseIdentifier;
};

export const CASE_CREATED = "case_created";
export const CASE_CREATION_ERROR = "case_creation_error";
export const postCase = (investigationId, label) => {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/case`;
      const signingObj = signString("POST", uri);
      const caseIdentifier = createCaseIdentifier();
      const payload = {
        investUri: `investigation/${investigationId}`,
        caseUri: `case/${caseIdentifier}`,
        label: label ? label : "Untitled Case",
      };
      const res = await axios.post(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const caseUri = res.headers.location;
      dispatch({
        type: CASE_CREATED,
        payload: {
          caseUri,
        },
      });
      return caseUri;
    } catch (error) {
      dispatch({
        type: CASE_CREATION_ERROR,
        payload:
          "Error creating case. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};

export const CASE_PATCHED = "case_patched";
export const CASE_PATCHING_ERROR = "case_patching_error";
export const patchCase = (caseId, label) => {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/case/${caseId}`;
      const signingObj = signString("PATCH", uri);
      const revisionDate = Math.round(Date.now() / 1000);
      const payload = {
        revisionDate,
        label,
      };
      const res = await axios.patch(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      //show toast...
      dispatch({
        type: CASE_PATCHED,
      });
    } catch (error) {
      dispatch({
        type: CASE_PATCHING_ERROR,
        payload:
          "Error patching case. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};
