import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import analyticsReducer from "./analyticsReducer";
import exportReducer from "./exportReducer";
import dashboardReducer from "./dashboardReducer";
import activityReducer from "./activityReducer";
import manageReducer from "./manageReducer";
import investCreationReducer from "./investigationReducer";
import investManagementReducer from "./investManagementReducer";
import surveyCreationReducer from "./surveyReducer";
import userManagement from "./userManagementReducer";
import errorReducer from "./errorReducer";
import queryReducer from "./queryReducer";
import dataReducer from "./dataReducer";
import groupsReducer from "./groupsReducer";
import investigationLinkReducer from "./investigationLinkReducer";
import groupLink from "./groupLinkReducer";
import deepLink from "./deepLinkReducer";
import groupByCase from "./groupByCaseReducer";
import dataExports from "./dataExportsReducer";
import surveys from "./surveysReducer";
import permissions from "./permissionsReducer";
import billing from "./billingReducer";
import pendingDataExports from "./pendingDataExportsReducer";
import savedViews from "./savedViewsReducer";
import responsesCount from "./responsesCountReducer";

import casesReducer from "./casesReducer";
import caseResponsesReducer from "./caseResponsesReducer";
import pristineReducer from "./pristineReducer";

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  analytics: analyticsReducer,
  exportData: exportReducer,
  dashboard: dashboardReducer,
  activities: activityReducer,
  manage: manageReducer,
  investCreation: investCreationReducer,
  investManage: investManagementReducer,
  survey: surveyCreationReducer,
  userManagement: userManagement,
  error: errorReducer,
  queries: queryReducer,
  responses: dataReducer,
  caseData: casesReducer,
  caseResponses: caseResponsesReducer,
  pristine: pristineReducer,
  groups: groupsReducer,
  investigationLink: investigationLinkReducer,
  groupLink,
  deepLink,
  groupByCase,
  dataExports,
  surveys,
  permissions,
  billing,
  pendingDataExports,
  savedViews,
  responsesCount,
});

export default rootReducer;
