import { INVESTIGATION_GROUPS_READ } from "../actions/manageGroups";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case INVESTIGATION_GROUPS_READ:
      return action.payload;
    default:
      break;
  }
  return state;
}
