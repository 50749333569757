import {
  GOT_SURVEYS,
  SURVEYS_ERROR,
  GOT_SURVEY,
  SURVEY_ERROR,
  DESTROY_SURVEY,
  POST_ERROR,
  PATCH_ERROR,
} from "../actions/surveyCreation";
import { SAVED_INVESTIGATION } from "../actions/dashboard";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case GOT_SURVEYS:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysData: action.payload,
        surveysError: undefined,
      });
    case GOT_SURVEY:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveyData: action.payload,
        surveyError: undefined,
      });
    case SURVEY_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveyError: action.payload,
      });
    case SURVEYS_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysError: action.payload,
      });
    case DESTROY_SURVEY:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveyData: undefined,
        surveyError: undefined,
      });
    case POST_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        postError: action.payload,
      });
    case PATCH_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        patchError: action.payload,
      });
  }
  return state;
}
