import { GOT_USERS, USERS_ERROR } from "../actions/manage";
import {
  SAVED_INVESTIGATION,
  SAVED_TITLE,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";
import { GOT_INVEST_DETAILS } from "../actions/investManagement";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case SAVED_TITLE:
      return {
        ...state,
        savedInvestTitle: action.payload,
      };
    case GOT_USERS:
      return {
        ...state,
        authorizedUsers: action.payload,
        usersError: undefined,
      };
    case USERS_ERROR:
      return {
        ...state,
        usersError: action.payload,
      };
    case GOT_INVEST_DETAILS:
      return {
        ...state,
        investDetails: action.payload,
      };
    default:
      break;
  }
  return state;
}
