import { GOT_ERROR, RESET_ERROR } from "../actions/errors";

const initialState = {
  appError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_ERROR:
      return {
        ...state,
        appError: action.payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        appError: null,
      };
  }
  return state;
}
