import { destroy } from "redux-form";
import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const __ = window.async;

export const GOT_USERS = "got_users";
export const GOT_AVATAR = "got_avatar";
export const AVATAR_ERROR = "avatar_error";
export const USERS_ERROR = "users_error";
export const USER_DELETED = "user_deleted";
export const DELETE_ERROR = "delete_error";
export const POSTED_INVITES = "posted_invites";
export const INVITES_POST_ERROR = "invites_post_error";
export const GOT_PERMISSIONS = "got_permissions";
export const PERMISSIONS_ERROR = "permissions_error";

export function getUsersAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/users`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      res.data.currentUsers.forEach((user, i) => {
        res.data.currentUsers[i].permissionsArr = [];
        for (let key in user.permissions) {
          if (user.permissions.hasOwnProperty(key)) {
            if (user.permissions[key] === true) {
              if (key === "viewEntries") {
                res.data.currentUsers[i].permissionsArr.push("View all");
              } else if (key === "editCases") {
                res.data.currentUsers[i].permissionsArr.push("Edit cases");
              } else if (key === "export") {
                res.data.currentUsers[i].permissionsArr.push("Export");
              } else if (key === "manageInvest") {
                res.data.currentUsers[i].permissionsArr.push("Manage");
              }
            } else {
              if (key === "viewEntries") {
                res.data.currentUsers[i].permissionsArr.push("View own");
              }
            }
          }
        }
      });

      res.data.pendingUsers.forEach((user, i) => {
        res.data.pendingUsers[i].permissionsArr = [];
        for (let key in user.permissions) {
          if (user.permissions.hasOwnProperty(key)) {
            if (user.permissions[key] === true) {
              if (key === "viewEntries") {
                res.data.pendingUsers[i].permissionsArr.push("View all");
              } else if (key === "editCases") {
                res.data.pendingUsers[i].permissionsArr.push("Edit cases");
              } else if (key === "export") {
                res.data.pendingUsers[i].permissionsArr.push("Export");
              } else if (key === "manageInvest") {
                res.data.pendingUsers[i].permissionsArr.push("Manage");
              }
            } else {
              if (key === "viewEntries") {
                res.data.pendingUsers[i].permissionsArr.push("View own");
              }
            }
          }
        }
      });

      __.eachOf(
        res.data.currentUsers,
        (user, i, cb) => {
          if (user.avatar) {
            const tempUri = `${NEW_BACKEND_URL}${user.avatar.replace(
              "/mediab64",
              ""
            )}/base64`;
            axios
              .get(`${tempUri}`)
              .then((response) => {
                res.data.currentUsers[i].avatarImg = response.data;
                cb();
              })
              .catch((err) => {
                if (err) {
                  console.error(err);
                  dispatch(manageError(err));
                }
                cb();
              });
          } else {
            cb();
          }
        },
        (err) => {
          if (err) {
            console.error(err);
            dispatch({
              type: USERS_ERROR,
              payload:
                "Error retrieving users. Please try again, or contact an administrator.",
            });
          } else {
            dispatch({
              type: GOT_USERS,
              payload: res.data,
            });
          }
        }
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: USERS_ERROR,
        payload:
          "Error retrieving users. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getInvestigationPermissionsAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/permissions`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_PERMISSIONS,
        payload: res.data.permissions,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: PERMISSIONS_ERROR,
        payload:
          "Error retrieving permissions. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function deleteUserAction(userId, investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/user/${userId}/investigation/${investigationId}`;
      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: USER_DELETED,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_ERROR,
        payload:
          "Error deleting user. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function deleteInviteAction(inviteId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/invite/${inviteId}`;
      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: USER_DELETED,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_ERROR,
        payload:
          "Error deleting user. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function resendInviteAction(inviteId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/invite/${inviteId}`;
      const signingObj = signString("POST", uri);
      const res = await axios.post(
        `${uri}`,
        {},
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function resetUsersError() {
  return (dispatch) => {
    dispatch({
      type: USERS_ERROR,
      payload: undefined,
    });
  };
}

export function destroyFormsAction(userId, investigationId) {
  return (dispatch) => {
    dispatch(destroy(`PermissionsForm_${userId + "-" + investigationId}`));
  };
}

export function postInvitesAction(values, investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/invites`;
      const body = {};

      body.invites = values;

      const signingObj = signString("POST", uri);
      const res = await axios.post(`${uri}`, body, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: POSTED_INVITES,
        payload: res.data,
      });

      window.userGuiding.track("segment", {
        userInvited: true,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVITES_POST_ERROR,
        payload:
          "Error retrieving users. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function patchPermissionsAction(values, investigationId) {
  return async (dispatch) => {
    try {
      console.log("patch values", values);

      const uri = `${NEW_BACKEND_URL}/user/${values.identifier}/permissions`;
      const body = {};

      body.groupings = {};
      body.groupings.investigation = investigationId;
      body.groupings.groupings = [];

      body.permissions = [];

      if (values.viewPermissions === "ownEntries") {
        let tempObj = {
          type: "view",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      } else if (values.viewPermissions === "allEntries") {
        let tempObj = {
          type: "view",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.addEntriesPermissions) {
        let tempObj = {
          type: "addEntries",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      } else {
        let tempObj = {
          type: "addEntries",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.exportPermissions) {
        let tempObj = {
          type: "exportData",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      } else {
        let tempObj = {
          type: "exportData",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.manageInvestPermissions) {
        let tempObj = {
          type: "manageStudy",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      } else {
        let tempObj = {
          type: "manageStudy",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.editCases) {
        let tempObj = {
          type: "editCases",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      } else {
        let tempObj = {
          type: "editCases",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.createStudy) {
        let tempObj = {
          type: "createStudy",
          action: "ADD",
        };
        body.groupings.groupings.push(tempObj);
      } else {
        let tempObj = {
          type: "createStudy",
          action: "DEL",
        };
        body.groupings.groupings.push(tempObj);
      }

      if (values.surveysPermissions) {
        body.permissions.push({
          type: "SURVEYS",
          action: "SET",
          value: values.surveysPermissions,
        });
        body.investigationIdentifier = investigationId;
      }

      if (values.denyPermissionsFor) {
        body.permissions.push({
          type: "SURVEY",
          action: "DENY_EDIT_RESPONSES",
          value:
            values.denyPermissionsFor.length > 0
              ? values.denyPermissionsFor
              : [],
        });
      }

      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(`${uri}`, body, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: POSTED_INVITES,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVITES_POST_ERROR,
        payload:
          "Error retrieving users. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
