import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = {
  button: {
    marginLeft: 18,
  },
  link: {
    textDecoration: "none",
  },
  icon: {
    marginRight: 15,
    fontSize: 16,
  },
  iconEnd: {
    marginLeft: 15,
    fontSize: 16,
  },
  smallIcon: {
    marginRight: 10,
  },
  cellMode: {
    margin: "0 0 0 -12px",
    padding: "8px 10px",
  },
  noText: {
    padding: "8px 10px",
    minWidth: 0,
    minHeight: "38px",
    "& $icon": {
      marginRight: 0,
    },
  },
  noMargin: {
    margin: 0,
  },
  help: {
    cursor: "help !important",
  },
  disabled: {
    color: "#ffffff !important",
  },
  gray: {
    color: "rgba(0, 0, 0, 0.26) !important",
  },
  thick: {
    borderWidth: 2,
    "&:hover": {
      borderWidth: 2,
    },
  },
  new: {
    marginLeft: 8,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: 6,
    paddingLeft: 6,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 4,
    letterSpacing: 2,
    backgroundColor: "#006ef5",
    color: "#fff",
    fontSize: 10,
    textTransform: "uppercase",
  },
};

const TsIconButton = React.forwardRef(
  (
    {
      classes,
      className,
      text,
      icon,
      onClick,
      cellMode,
      noMargin,
      tooltip,
      url,
      disabled,
      loading,
      linkProps,
      thick,
      iconPosition,
      classNameForClick,
      ...args
    },
    ref
  ) => {
    let factory;
    if (url) {
      factory = (children) => (
        <Link className={classes.link} to={url} {...linkProps}>
          {children}
        </Link>
      );
    } else {
      factory = (children) => <>{children}</>;
    }
    return factory(
      <div
        ref={ref}
        className={classnames(className, {
          [classes.help]: tooltip && disabled,
        })}
      >
        <Tooltip title={tooltip || ""} enterDelay={100}>
          <span>
            <Button
              className={classnames(classNameForClick, classes.button, {
                [classes.cellMode]: cellMode,
                [classes.noText]: !text,
                [classes.noMargin]: noMargin,
                [classes.disabled]: disabled,
                [classes.gray]: disabled && args.variant === "outlined",
                [classes.thick]: thick,
              })}
              variant="contained"
              color="primary"
              onClick={onClick}
              disabled={disabled || loading}
              {...args}
            >
              {loading && (
                <CircularProgress className={classes.icon} size={16} />
              )}
              {iconPosition != undefined && iconPosition == "END" ? (
                <div>
                  {text}
                  {icon &&
                    !loading &&
                    (React.isValidElement(icon) ? (
                      icon
                    ) : (
                      <FontAwesomeIcon
                        icon={icon}
                        className={classnames(classes.iconEnd, {
                          [classes.smallIcon]: args.size === "small",
                        })}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  {icon &&
                    !loading &&
                    (React.isValidElement(icon) ? (
                      icon
                    ) : (
                      <FontAwesomeIcon
                        icon={icon}
                        className={classnames(classes.icon, {
                          [classes.smallIcon]: args.size === "small",
                        })}
                      />
                    ))}
                  {text}
                  {args.isNew && <span className={classes.new}>New</span>}
                </div>
              )}
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  }
);

export default withStyles(styles)(TsIconButton);
