import {
  GOT_CASE_RESPONSES,
  CASE_RESPONSES_ERROR,
  SET_CASE_RESPONSES_LAST_KEY_LIST,
  NO_MORE_CASE_RESPONSES,
} from "../actions/cases";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_CASE_RESPONSES:
      return {
        ...state,
        responseData: action.payload,
        noMoreCaseResponses: false,
      };
    case CASE_RESPONSES_ERROR:
      return initialState;
    case SET_CASE_RESPONSES_LAST_KEY_LIST:
      return {
        ...state,
        lastKeyList: action.payload,
      };
    case NO_MORE_CASE_RESPONSES:
      return {
        ...state,
        noMoreCaseResponses: action.payload,
      };
    default:
      return state;
  }
}
