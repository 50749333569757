import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { checkAuth } from "./actions/signIn";

export default function (ComposedComponent) {
  class NotAuthentication extends Component {
    componentWillMount() {
      this.props.checkAuth();
      if (this.props.authenticated) {
        this.props.history.push("/dash");
      }
    }

    componentWillUpdate(nextProps) {
      this.props.checkAuth();
      if (nextProps.authenticated) {
        this.props.history.push("/dash");
      }
    }

    PropTypes = {
      router: PropTypes.object,
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps, { checkAuth })(NotAuthentication);
}
