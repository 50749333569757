import { getInvestigationsAction, saveInvestigationAction } from "./dashboard";
import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");

export const INVESTIGATION_CREATED = "investigation_created";
export const INVEST_ERROR = "invest_error";

export function postInvestigationAction(values) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation`;
      const signingObj = signString("POST", uri);
      return axios
        .post(uri, values, {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        })
        .then((res) => {
          console.log("Invest Data: ", res.data);
          let payload = res.data;
          payload.title = values.title;

          dispatch({
            type: INVESTIGATION_CREATED,
            payload: payload,
          });
          dispatch(getInvestigationsAction());
          return res;
        })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: INVEST_ERROR,
            payload:
              "Error creating study. Please try again, or contact an administrator.",
          });
          dispatch(manageError(error));
          return error;
        });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVEST_ERROR,
        payload:
          "Error creating study. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
