import { GOT_PERMISSIONS } from "../actions/dashboard";

export default function (state = {}, action) {
  switch (action.type) {
    case GOT_PERMISSIONS:
      return {
        ...action.payload,
      };
    default:
      break;
  }
  return state;
}
