import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");
const __ = window.async;

export const GOT_QUERIES = "got_queries";
export const QUERIES_ERROR = "queries_error";

export function getQueriesAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${BACKEND_URL}/investigation/${investigationId}/queries`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      res.data.queries.sort((a, b) => {
        return Date.parse(b.creation) - Date.parse(a.creation);
      });

      let avatarObj = {};
      res.data.queries.forEach((e) => {
        avatarObj[e.avatar] = e.query;
      });

      __.eachOf(
        avatarObj,
        (avatar, key, cb) => {
          const tempUri = `${BACKEND_URL}${key}`;
          axios
            .get(`${tempUri}`)
            .then((response) => {
              res.data.queries.forEach((e, i) => {
                if (e.avatar === key) {
                  res.data.queries[i].avatarImg = response.data;
                }
              });
              cb();
            })
            .catch((err) => {
              if (err) {
                console.error(err);
                dispatch(manageError(err));
              }
            });
        },
        (err) => {
          if (err) {
            dispatch(manageError(err));
          } else {
            dispatch({
              type: GOT_QUERIES,
              payload: res.data.queries,
            });
          }
        }
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: QUERIES_ERROR,
        payload:
          "Error retrieving queries. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function queryFlagAction(queryId, investigationId, queryStatus) {
  return async (dispatch) => {
    try {
      const uri = `${BACKEND_URL}/query/${queryId}/flag?investigationId=${investigationId}`;
      const payload = {};
      payload.status = queryStatus === "open" ? "1" : "0";

      const signingObj = signString("PATCH", uri);
      console.time("PATCH");
      const res = await axios.patch(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      console.timeEnd("PATCH");
      console.time("GET");
      dispatch(getQueriesAction(investigationId)).then(() => {
        console.timeEnd("GET");
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: QUERIES_ERROR,
        payload:
          "Error patching queries. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
