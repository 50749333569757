import { DEEP_LINK_READ, DEEP_LINK_DATA_READ } from "../actions/deepLinks";

const initialState = {
  link: null,
  data: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEEP_LINK_READ:
      const { payload: link } = action;
      return { ...initialState, link };
    case DEEP_LINK_DATA_READ:
      const { payload: data } = action;
      return { ...state, data };
    default:
      break;
  }
  return state;
}
