import React, { Component, Suspense, lazy, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import "typeface-roboto";
import "typeface-montserrat";
import "./i18n";

import RequireAuth from "./RequireAuth";
import RequireUnAuth from "./RequireUnAuth";

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";

import { AUTHENTICATED, UNAUTHENTICATED } from "./actions/signIn";
import rootReducer from "./reducers/index";

import formDestroyFix from "./middleware/formDestroyFix";
import ErrorBoundary from "./ErrorBoundary";
import ErrorPage from "./ErrorPage";
import EmailVerificationContainer from "./Login/EmailVerificationContainer";
import EConsent from "./EConsent/EConsent";
import OAuthStudyPagesContainer from "./Login/OAuthStudyPages/OAuthStudyPagesContainer";
import SignUpContainer from "./Login/SignUpContainer";

const PostSignup = lazy(() => import("./PostSignup/PostSignup"));
const OnboardingSurvey = lazy(() =>
  import("./OnboardingSurvey/OnboardingSurvey")
);
const DashboardScreenContainer = lazy(() =>
  import("./Dashboard/DashboardScreenContainer")
);
const Logout = lazy(() => import("./Login/Logout"));
const SignInContainer = lazy(() => import("./Login/SignInContainer"));
const BranchContainer = lazy(() => import("./Login/BranchContainer"));
const SurveyContainer = lazy(() => import("./Survey/SurveyContainer"));
const SurveyStandalone = lazy(() => import("./Survey/SurveyStandalone"));
const SnackbarErrors = lazy(() => import("./SnackbarErrors"));
const Organization = lazy(() => import("./Organizations"));
const StudyExternalLinkRedirect = lazy(() =>
  import("./StudyPages/StudyExternalLinkRedirect")
);

const MobileLoginRedirect = lazy(() => import("./Login/MobileLogin"));

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(reduxThunk, formDestroyFix),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

// const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
// const store = createStoreWithMiddleware(reducers);

let user = localStorage.getItem("user");
try {
  JSON.parse(user);
} catch (e) {
  localStorage.removeItem("user");
  user = null;
}
if (user && JSON.parse(user).validDate > new Date().getTime()) {
  store.dispatch({ type: AUTHENTICATED });
} else {
  localStorage.removeItem("user");
  store.dispatch({ type: UNAUTHENTICATED });
}

const Refresh = ({ path = "/" }) => (
  <Route
    path={path}
    component={({ history, location, match }) => {
      history.replace({
        ...location,
        pathname: location.pathname.substring(match.path.length),
      });
      return null;
    }}
  />
);

class App extends Component {
  handleMouseClick = () => {};

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    window.addEventListener("click", this.handleMouseClick);

    // window.addEventListener(
    //   "error",
    //   (event) => {
    //     const { error } = event;
    //     // Skip the first error, it is always irrelevant in the DEV mode.
    //     if (
    //       error.stack?.indexOf("invokeGuardedCallbackDev") >= 0 &&
    //       !error.alreadySeen
    //     ) {
    //       error.alreadySeen = true;
    //       event.preventDefault();
    //       return;
    //     }
    //     console.log(`window event error: ${error}`);
    //     this.setState({ error: error });
    //   },
    //   { capture: true }
    // );
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleMouseClick);
  }

  render() {
    return this.state.error ? (
      <ErrorPage />
    ) : (
      <Provider store={store}>
        <ErrorBoundary>
          <Router>
            <div className="App">
              <Suspense fallback={<div>...</div>}>
                <SnackbarErrors />
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/login" />}
                  />
                  <Route
                    exact
                    path="/post-signup"
                    render={(routeProps) => {
                      const Component = RequireUnAuth(PostSignup);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    exact
                    path="/e-consent-verification"
                    render={(routeProps) => {
                      const Component = RequireAuth(EConsent);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    exact
                    path="/onboarding-survey"
                    render={(routeProps) => {
                      const Component = RequireAuth(OnboardingSurvey);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/dash"
                    render={(routeProps) => {
                      const Component = RequireAuth(DashboardScreenContainer);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/organizations"
                    render={(routeProps) => {
                      const Component = RequireAuth(Organization);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/signout"
                    render={(routeProps) => {
                      const Component = RequireAuth(Logout);
                      return <Component {...routeProps} fromSignOut={true} />;
                    }}
                  />

                  <Route
                    exact
                    path="/login"
                    render={(routeProps) => {
                      const Component = RequireUnAuth(SignInContainer);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    exact
                    path="/signup"
                    render={(routeProps) => {
                      const Component = RequireUnAuth(SignUpContainer);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route exact path="/join" component={BranchContainer} />
                  <Route
                    path="/surveys/:surveyIdentifier"
                    component={SurveyContainer}
                  />
                  <Route
                    path="/forms/:surveyIdentifier"
                    component={SurveyContainer}
                  />
                  <Route
                    path="/form/:formId"
                    render={(routeProps) => {
                      const Component = RequireAuth(SurveyStandalone);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/verify-email"
                    render={(routeProps) => {
                      const Component = RequireUnAuth(
                        EmailVerificationContainer
                      );
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/studypages/success"
                    render={(routeProps) => {
                      const Component = RequireUnAuth(OAuthStudyPagesContainer);
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/investigation/:investigationId"
                    render={(routeProps) => {
                      const Component = StudyExternalLinkRedirect;
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Route
                    path="/mobile/login"
                    render={(routeProps) => {
                      const Component = MobileLoginRedirect;
                      return <Component {...routeProps} />;
                    }}
                  />
                  <Refresh path="/refresh" />
                  <Route
                    path="/v2/dash/export"
                    component={() => {
                      window.location.replace(
                        `${process.env.REACT_APP_FRONTEND_BASE_URL}/v2/dash/export`
                      );
                      return null;
                    }}
                  />
                  <Route
                    path="/v2/dash/activity"
                    component={() => {
                      window.location.replace(
                        `${process.env.REACT_APP_FRONTEND_BASE_URL}/v2/dash/activity`
                      );
                      return null;
                    }}
                  />
                  <Route
                    path="/v2/dash/manage"
                    component={() => {
                      window.location.replace(
                        `${process.env.REACT_APP_FRONTEND_BASE_URL}/v2/dash/manage`
                      );
                      return null;
                    }}
                  />
                  <Route
                    component={() => {
                      window.location.replace("https://teamscope.co/404");
                      return null;
                    }}
                  />
                </Switch>
              </Suspense>
            </div>
          </Router>
        </ErrorBoundary>
      </Provider>
    );
  }
}
export default App;
// Adding a change for deployment
