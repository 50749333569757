import moment from "moment";

const jsSHA = require("jssha");

function hexToBase64(hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      .map((a) => {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join("")
  );
}

function hexToArrayBuffer(hex) {
  const view = new Uint8Array(hex.length / 2);

  for (let i = 0; i < hex.length; i += 2) {
    view[i / 2] = parseInt(hex.substring(i, i + 2), 16);
  }

  return view;
}

function stringToArrayBuffer(str) {
  const enc = new TextEncoder(); // always utf-8
  return enc.encode(str);
}

export default function createSigningString(method, url, keyUri, keyData) {
  if (!keyUri || !keyData) {
    const localKeys = JSON.parse(localStorage.getItem("user"));
    keyUri = localKeys.uri;
    keyData = localKeys.data;
  }
  const parsedUrl = "/" + url.split("/").slice(3).join("/");
  const parsedKeyUri = keyUri.split("/")[2];
  const b64KeyUri = hexToBase64(parsedKeyUri);
  const date = moment().utc().format("ddd, D MMM YYYY HH:mm:ss [GMT]");
  const shaObj = new jsSHA("SHA-256", "ARRAYBUFFER");
  shaObj.setHMACKey(hexToArrayBuffer(keyData), "ARRAYBUFFER");
  shaObj.update(
    stringToArrayBuffer(method + " " + parsedUrl + " HTTP/1.1\ndate: " + date)
  );
  const b64Hmac = shaObj.getHMAC("B64");

  // const keyUriArray = CryptoJS.lib.WordArray.create(hexToArrayBuffer(keyData));
  // const urlStringArray = CryptoJS.lib.WordArray.create(stringToArrayBuffer(method + " " + parsedUrl + " HTTP/1.1\ndate: " + date));
  // const hmac = CryptoJS.HmacSHA256(urlStringArray, keyUriArray);
  // const b64Hmac = CryptoJS.enc.Base64.stringify(hmac);

  let signingObj = {};
  signingObj.date = date;
  signingObj.string =
    'Signature keyId="' +
    b64KeyUri +
    '",algorithm="hmac-sha256",headers="request-line date",signature="' +
    b64Hmac +
    '"';

  return signingObj;
}
