import {
  GOT_SURVEYS,
  SURVEYS_ERROR,
  GOT_SURVEY,
  SURVEY_ERROR,
  GOT_GRAPH,
  GRAPH_ERROR,
  SAVED_GRAPH,
  DELETED_GRAPH,
  RESET_GRAPHS,
  RESET_PROPS,
} from "../actions/analytics";
import {
  SAVED_INVESTIGATION,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";

const initialState = {
  graphData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PROPS:
      return {
        ...state,
        surveysData: undefined,
        surveysError: undefined,
        surveyData: undefined,
        surveyError: undefined,
      };
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case RESET_GRAPHS:
      return {
        ...state,
        graphData: [],
      };
    case GOT_SURVEYS:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysData: action.payload,
        surveysError: undefined,
      });
    case SURVEYS_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysError: undefined,
      });
    case GOT_SURVEY:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveyData: action.payload,
        surveyError: undefined,
      });
    case SURVEY_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveyError: action.payload,
      });
    case GOT_GRAPH:
      if (
        state.graphData.filter(
          (e) => e.values.identifier === action.payload.values.identifier
        ).length > 0
      ) {
        let updatedGraph = {
          ...state,
          graphData: state.graphData.slice().map((graph, i) =>
            graph.values.identifier === action.payload.values.identifier
              ? {
                  ...graph,
                  config: action.payload.config,
                }
              : graph
          ),
        };
        updatedGraph.graphData = updatedGraph.graphData.filter(
          (e) => e.values.investigation === action.payload.savedInvest
        );
        updatedGraph.graphData.sort((a, b) => {
          return new Date(a.values.timestamp) < new Date(b.values.timestamp)
            ? -1
            : 1;
        });
        console.log("UG", updatedGraph);
        return updatedGraph;
      } else {
        let payload = {
          values: action.payload.values,
          config: action.payload.config,
        };
        let addedGraph = {
          ...state,
          graphData: [...state.graphData, payload],
        };
        addedGraph.graphData = addedGraph.graphData.filter(
          (e) => e.values.investigation === action.payload.savedInvest
        );
        addedGraph.graphData.sort((a, b) => {
          return new Date(a.values.timestamp) < new Date(b.values.timestamp)
            ? -1
            : 1;
        });
        console.log("AG,", addedGraph);
        return addedGraph;
      }

    case GRAPH_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        graphError: action.payload,
      });
    case SAVED_GRAPH:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysData: null,
        surveyData: null,
      });
    case DELETED_GRAPH:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        graphData: state.graphData
          .slice()
          .filter((graph, i) => graph.values.identifier !== action.payload),
      });
  }
  return state;
}
