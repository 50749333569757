import { GOT_SAVED_VIEWS } from "../actions/savedViews";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_SAVED_VIEWS:
      return action.payload;
    default:
      break;
  }
  return state;
}
