import { getUserAvatar } from "./dashboard";
import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");

export const UPDATED_USER = "updated_user";
export const UPDATED_USER_ERROR = "updated_user_error";
export const USER_EMAIL_CHANGED = "user_email_changed;";
export const USER_EMAIL_ERROR = "user_email_error";
export const USER_PASSWORD_FAIL = "user_password_fail";
export const USER_PASSWORD_CHANGED = "user_password_changed;";
export const USER_PASSWORD_ERROR = "user_password_error";
export const USER_EMAIL_FAIL = "user_email_fail";

export function patchUserDetails(values) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/user/${localStorage.getItem(
        "userHex"
      )}/details`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(`${uri}`, values, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      if (values.avatar) {
        localStorage.setItem("userAvatar", "mediab64/" + res.headers.location);
        dispatch(getUserAvatar("mediab64/" + res.headers.location));
      }
      if (values.name) {
        localStorage.setItem("userName", values.name);
      }
      if (values.jobTitle) {
        localStorage.setItem("userJob", values.jobTitle);
      }

      dispatch({
        type: UPDATED_USER,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATED_USER_ERROR,
        payload:
          "Error updating user details. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function patchAvatar(values) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/user/${localStorage.getItem(
        "userHex"
      )}/details`;
      const signingObj = signString("PATCH", uri);
      const res = await axios.patch(`${uri}`, values, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      localStorage.setItem("userAvatar", "/mediab64/" + res.headers.location);
      dispatch(getUserAvatar("/mediab64/" + res.headers.location));

      dispatch({
        type: UPDATED_USER,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATED_USER_ERROR,
        payload:
          "Error updating user details. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
