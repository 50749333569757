export const PRISTINE = "PRISTINE";
export const NAV_LINK_CLICK = "NAV_LINK_CLICK";

function setPristine(boolean) {
  return {
    type: PRISTINE,
    pristine: boolean,
  };
}

function navLinkClick(boolean, path) {
  return {
    type: NAV_LINK_CLICK,
    navLinkClicked: boolean,
    navLinkPath: path,
  };
}

export function handlePristine(boolean) {
  return (dispatch) => {
    dispatch(setPristine(boolean));
  };
}

export function handleNavLinkClick(boolean, path) {
  return (dispatch) => {
    dispatch(navLinkClick(boolean, path));
  };
}
