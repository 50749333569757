import axios from "axios";

import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";

import { manageError } from "./errors";

export const SAVED_VIEW_CREATED = "SAVED_VIEW_CREATED";
export function postInvestigationView(investigationIdentifier, payload) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/view`;
      const signingObj = signString("POST", uri);
      const response = await axios.post(
        uri,
        { title: "Untitled view", ...payload },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );
      dispatch({
        type: SAVED_VIEW_CREATED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export const GOT_SAVED_VIEWS = "GOT_SAVED_VIEWS";
export function getInvestigationViews(investigationIdentifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/view`;
      const signingObj = signString("GET", uri);
      const response = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GOT_SAVED_VIEWS,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export const GOT_SAVED_VIEW = "GOT_SAVED_VIEW";
export function getView(viewIdentifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/view/${viewIdentifier}`;
      const signingObj = signString("GET", uri);
      const response = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GOT_SAVED_VIEW,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export const GOT_SAVED_VIEW_RESPONSES = "GOT_SAVED_VIEW_RESPONSES";
export function getViewResponses(viewIdentifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/view/${viewIdentifier}/responses`;
      const signingObj = signString("GET", uri);
      const response = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GOT_SAVED_VIEW_RESPONSES,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export const SAVED_VIEW_UPDATED = "SAVED_VIEW_UPDATED";
export function patchView(viewIdentifier, payload) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/view/${viewIdentifier}`;
      const signingObj = signString("PATCH", uri);
      const response = await axios.patch(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: SAVED_VIEW_UPDATED,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export const SAVED_VIEW_DELETED = "SAVED_VIEW_DELETED";
export function deleteView(viewIdentifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/view/${viewIdentifier}`;
      const signingObj = signString("DELETE", uri);
      const response = await axios.delete(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: SAVED_VIEW_DELETED,
      });
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}
