import { GOT_CASES, CASES_ERROR, NO_MORE_CASES } from "../actions/cases";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_CASES:
      return action.payload;
    case CASES_ERROR:
      return initialState;
    case NO_MORE_CASES:
      return {
        ...state,
        noMoreCases: action.payload,
      };
    default:
      return state;
  }
}
