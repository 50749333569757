import React from "react";
import { Paper, withStyles } from "@material-ui/core";
import LightLogoPath from "../Assets/LightLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StudyPagesAccountCreated = ({ classes }) => {
  return (
    <Paper className={classes.paperRoot}>
      <div className={classes.paperDiv}>
        <img
          className={classes.logo}
          src={LightLogoPath}
          alt="Studypages Data"
        />
        <div>
          <FontAwesomeIcon className={classes.emailIcon} />
        </div>
        <h1 className={classes.header}>New StudyPages Account!</h1>
        <p className={classes.confirmationLabel}>
          Your studypages account has been created. Please check you email for
          set password link.
        </p>
      </div>
    </Paper>
  );
};

const styles = {
  paperRoot: {
    position: "fixed",
    left: "50%",
    top: "40%",
    overflow: "auto",
    width: "100%",
    height: "auto",
    maxWidth: 688,
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: 0,
    paddingTop: 0,
    transform: "translate(-50%, -40%)",
    color: "#272a2e",
    fontSize: 14,
  },
  paperDiv: {
    diplay: "flex",
    width: "90vw",
    maxWidth: 400,
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: 40,
    paddingBottom: 100,
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    paddingTop: 0,
    paddingBottom: 20,
    display: "inline-block",
  },
  emailIcon: {
    paddingTop: 20,
    fontFamily: "'Fa 400', sans-serif",
    color: "#b8c2cc",
    fontSize: 60,
    marginTop: 0,
    marginBottom: 0,
  },
  header: {
    paddingBottom: 20,
    textAlign: "center",
    maxWidth: "90vw",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 0,
    paddingTop: 20,
    marginTop: 0,
    fontSize: 28,
    fontWeight: 700,
  },
  label: {
    paddingBottom: 4,
  },
};

export default withStyles(styles)(StudyPagesAccountCreated);
