import { GOT_ACTIVITIES, ACTIVITIES_ERROR } from "../actions/activityMonitor";
import {
  SAVED_INVESTIGATION,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case GOT_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
      };
    case ACTIVITIES_ERROR:
      return {
        ...state,
      };
  }
  return state;
}
