import { withStyles } from "@material-ui/core";
import React from "react";
import TsPositiveButton from "../../shared/TsPositiveButton";

const OAuthAccessDenied = ({ classes, onLoginClick }) => {
  return (
    <div className={classes.root}>
      <h3>StudyPages Denied the Access!</h3>
      <br />
      Please try again.
      <div className={classes.verticalCenter}>
        <TsPositiveButton
          text={"Go back to Login Screen"}
          extras={classes.positiveButton}
          onClick={onLoginClick}
        />
      </div>
    </div>
  );
};

const styles = {
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    display: "inline-block",
    marginRight: 16,
  },
  positiveButton: {
    float: "center",
    fontSize: "14px",
    fontWeight: "400",
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 48,
  },
};

export default withStyles(styles)(OAuthAccessDenied);
