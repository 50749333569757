import {
  GOT_SURVEYS,
  SURVEYS_ERROR,
  GOT_EXPORT,
  EXPORT_ERROR,
} from "../actions/export";
import {
  SAVED_INVESTIGATION,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case GOT_SURVEYS:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysData: action.payload,
      });
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case SURVEYS_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        surveysError: action.payload,
      });
    case GOT_EXPORT:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        exportData: action.payload,
      });
    case EXPORT_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        exportError: action.payload,
      });
  }
  return state;
}
