import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import axios from "axios";
import { NEW_BACKEND_URL } from "../middleware/networking";

const qs = require("querystring-browser");
const uniqid = require("uniqid");

export const GOT_SURVEYS = "got_surveys";
export const SURVEYS_ERROR = "surveys_error";
export const GOT_SURVEY = "got_survey";
export const SURVEY_ERROR = "survey_error";
export const DESTROY_SURVEY = "destroy_survey";
export const POST_ERROR = "post_error";
export const PATCH_ERROR = "patch_error";

export function getSurveysAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/surveys`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GOT_SURVEYS,
        payload: res.data.surveys,
      });
      return res.data.surveys;
    } catch (error) {
      console.error(error);
      dispatch({
        type: SURVEYS_ERROR,
        payload:
          "Error retrieving surveys. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getSurveyAction(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Feature-Tags": "query-management",
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      res.data.items.forEach((e, index) => {
        res.data.items[index].internalId = uniqid("TEMPID-");
      });

      dispatch({
        type: GOT_SURVEY,
        payload: res.data,
      });

      return res.data;
    } catch (error) {
      console.error(error);
      dispatch({
        type: SURVEY_ERROR,
        payload:
          "Error retrieving survey. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getAnonymousSurveyAction(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}`;
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Feature-Tags": "query-management",
        },
      });

      res.data.items.forEach((e, index) => {
        res.data.items[index].internalId = uniqid("TEMPID-");
      });

      dispatch({
        type: GOT_SURVEY,
        payload: res.data,
      });

      return res.data;
    } catch (error) {
      throw error.response;
    }
  };
}

function makeid() {
  let text = "";
  let possible = "abcdef0123456789";

  for (let i = 0; i < 6; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function importFormAction(studyId, file, fileName) {
  return async (dispatch) => {
    try {

      const response = await fetch(file);
      const blob = await response.blob();
      const newFile = new File([blob], fileName, { type: blob.type });

      const url = `${NEW_BACKEND_URL}/study/${studyId}/import-new-form`;
      const form = new FormData();

      form.append("file", newFile);

      const signingObj = signString("POST", url);
      const res = await axios.post(url, form, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return res.data;
    } catch (error) {
      dispatch(manageError(error));
    }
  };
}

export function postSurveyAction(values) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey`;
      const bodyObj = {
        investigations: [
          {
            identifier: values.investigation,
            scrambled: true,
          },
        ],
        title: values.title,
        revisionDate: Math.round(new Date() / 1000),
      };

      if (values.description) {
        bodyObj.description = values.description;
      }

      if (values.fields) {
        bodyObj.items = [];
        values.fields.forEach((field, index) => {
          if (values.fields[index].identifier === undefined) {
            values.fields[index].identifier = makeid();
          }
        });
        values.fields.forEach((field, index) => {
          const tempObj = {};
          tempObj.type = field.type;
          tempObj.label = field.label;
          tempObj.identifier = field.identifier;
          if (field.conditions) {
            var searchArray = (array) => {
              const thisLevel = array;
              array.forEach((e, i) => {
                if (Array.isArray(e)) {
                  return (thisLevel[i] = searchArray(e));
                } else if (typeof e === "string" && e.includes("TEMPID-")) {
                  let fieldId = -1;
                  values.fields.forEach((x, fieldIndex) => {
                    if (x.internalId === e) {
                      return (fieldId = fieldIndex);
                    }
                  });
                  if (fieldId === -1) {
                    return console.error("FIELD NOT FOUND");
                  } else {
                    return (thisLevel[i] = `@${values.fields[
                      fieldId
                    ].identifier.toUpperCase()}`);
                  }
                } else {
                  return;
                }
              });
              return thisLevel;
            };
            const finalConditions = searchArray(field.conditions);
            tempObj.conditions = finalConditions;
          }
          if (field.description) {
            tempObj.description = field.description;
          }
          if (field.type === "Date") {
            tempObj.options = {
              mode: field.timeType,
            };
          }
          if (field.type === "Choice" || field.type === "Dropdown") {
            const tempOptions = {};
            tempOptions.choices = [];
            field.choices.forEach((choice, index) => {
              const tempChoice = {};
              tempChoice.label = choice.label;
              tempChoice.value = choice.value;
              return tempOptions.choices.push(tempChoice);
            });
            if (field.choiceType === "single") {
              tempOptions.maximumChoicesCount = 1;
            }
            tempOptions.allowOther = field.allowOther;
            tempObj.options = tempOptions;
          }
          if (field.type === "File") {
            let {
              allowedExtensions,
              maximumSize,
              maximumFileNumber,
              fileExtensionSwitch,
              fileSizeSwitch,
              fileNumberSwitch,
            } = field;
            allowedExtensions = (allowedExtensions || "").trim();
            allowedExtensions =
              allowedExtensions.length > 0 && fileExtensionSwitch
                ? allowedExtensions
                : null;
            maximumSize = fileSizeSwitch ? +maximumSize : null;
            maximumFileNumber = fileNumberSwitch ? +maximumFileNumber : null;
            tempObj.options = {
              allowedExtensions,
              maximumSize,
              maximumFileNumber,
            };
          }
          if (field.type === "Slider") {
            const {
              incrementSize,
              startValue,
              startLabel,
              endValue,
              endLabel,
            } = field;
            tempObj.options = {
              incrementSize,
              startValue,
              startLabel,
              endValue,
              endLabel,
            };
          }
          return bodyObj.items.push(tempObj);
        });
      }

      bodyObj.reminders = values.reminders ? values.reminders : null;
      bodyObj.anonymousResponsesAllowed = !!values.anonymousResponsesAllowed;

      const signingObj = signString("POST", uri);
      const res = await axios.post(`${uri}`, bodyObj, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch(getSurveysAction(values.investigation));

      return res.data.identifier;
    } catch (error) {
      console.error(error);
      dispatch({
        type: POST_ERROR,
        payload:
          "Error creating form. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function destroySurveyAction() {
  return (dispatch) => {
    dispatch({
      type: DESTROY_SURVEY,
    });
  };
}

export function validateFormImport(formId, file) {
  return async (dispatch) => {
    try {
      const url = `${NEW_BACKEND_URL}/form/${formId}/import-diff`;
      const form = new FormData();

      form.append("file", file);

      const signingObj = signString("POST", url);
      const res = await axios.post(url, form, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return res.data;
    } catch (error) {
      dispatch(manageError(error));
    }
  };
}

export function patchFormFromImport(formId, payload) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/form/${formId}/import`;

      const signingObj = signString("PATCH", uri);
      await axios.patch(`${uri}`, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch(getSurveysAction(payload.studyId));
    } catch (error) {
      dispatch(manageError(error));
    }
  };
}

export function getFormExportFile(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/form/${surveyId}/export`;
      let headers = {};
      const signingObj = signString("GET", uri);
      headers = {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      };
      const res = await axios({
        url: uri,
        method: "GET",
        responseType: "blob",
        headers,
      });
      const filename = decodeURIComponent(
        res.headers["content-disposition"].replace("attachment; filename=", "")
      );
      const filetype = res.headers["content-type"];
      const blob = new Blob([res.data]);
      const file = new File([blob], filename, { type: filetype });
      const url = window.URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function patchSurveyAction(values) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${values.identifier}`;
      const bodyObj = {
        investigations: [
          {
            identifier: values.investigation,
            scrambled: true,
          },
        ],
        title: values.title,
        revisionDate: Math.round(new Date() / 1000),
        items: [],
      };

      if (values.description) {
        bodyObj.description = values.description;
      }

      if (values.fields) {
        bodyObj.items = [];
        values.fields.forEach((field, index) => {
          if (!values.fields[index].identifier) {
            values.fields[index].identifier = makeid();
          }
        });
        values.fields.forEach((field, index) => {
          const tempObj = {};
          tempObj.type = field.type;
          tempObj.label = field.label;
          tempObj.identifier = field.identifier;
          tempObj.internalId = field.internalId;
          if (field.conditions) {
            var searchArray = (array) => {
              const thisLevel = array;
              array.forEach((e, i) => {
                if (Array.isArray(e)) {
                  return (thisLevel[i] = searchArray(e));
                } else if (typeof e === "string" && e.includes("TEMPID-")) {
                  let fieldId = -1;
                  values.fields.forEach((x, fieldIndex) => {
                    if (e.startsWith("@")) {
                      e = e.substring(1);
                    }
                    if (x.internalId === e) {
                      return (fieldId = fieldIndex);
                    }
                  });
                  if (fieldId === -1) {
                    return console.error("FIELD NOT FOUND");
                  } else {
                    return (thisLevel[i] = `@${values.fields[
                      fieldId
                    ].identifier.toUpperCase()}`);
                  }
                } else {
                  return;
                }
              });
              return thisLevel;
            };
            const finalConditions = searchArray(field.conditions);
            tempObj.conditions = finalConditions;
          }
          if (field.description) {
            tempObj.description = field.description;
          }
          if (
            field.formulaString &&
            Object.keys(field.formulaString).length !== 0
          ) {
            if (field.formulaString.includes("TEMPID-")) {
              values.fields.forEach((x, fieldIndex) => {
                field.formulaString = field.formulaString.replace(
                  x.internalId,
                  x.identifier
                );
              });
            }
            tempObj.formulaString = field.formulaString;
          } else {
            tempObj.formulaString = "";
          }
          if (field.default) {
            tempObj.default = field.default;
          } else {
            tempObj.default = "";
          }
          if (field.type === "Date") {
            tempObj.options = {
              mode: field.timeType,
            };
          }
          if (field.type === "Choice" || field.type === "Dropdown") {
            const tempOptions = {};
            tempOptions.choices = [];
            field.choices.forEach((choice, index) => {
              const tempChoice = {};
              tempChoice.label = choice.label;
              tempChoice.value = choice.value;
              return tempOptions.choices.push(tempChoice);
            });
            if (field.choiceType === "single") {
              tempOptions.maximumChoicesCount = 1;
            }
            tempOptions.allowOther = field.allowOther;
            tempObj.options = tempOptions;
          }
          if (field.type === "File") {
            let {
              allowedExtensions,
              maximumSize,
              maximumFileNumber,
              fileExtensionSwitch,
              fileSizeSwitch,
              fileNumberSwitch,
            } = field;
            allowedExtensions = (allowedExtensions || "").trim();
            allowedExtensions =
              allowedExtensions.length > 0 && fileExtensionSwitch
                ? allowedExtensions
                : null;
            maximumSize = fileSizeSwitch ? +maximumSize : null;
            maximumFileNumber = fileNumberSwitch ? +maximumFileNumber : null;
            tempObj.options = {
              allowedExtensions,
              maximumSize,
              maximumFileNumber,
            };
          }
          if (field.type === "Slider") {
            const {
              incrementSize,
              startValue,
              startLabel,
              endValue,
              endLabel,
            } = field;
            tempObj.options = {
              incrementSize: +incrementSize,
              startValue: +startValue,
              endValue: +endValue,
              startLabel,
              endLabel,
            };
          }
          if (field.type === "Matrix") {
            tempObj.options = {
              rows: field.rows,
              columns: field.columns,
              columnWidth: field.columnWidth,
            };
          }
          if (field.type === "Drilldown") {
            tempObj.options = {
              file: field.otherOptions.file,
            };
          }
          if (field.type === "Signature") {
            tempObj.options = {
              numberOfSignatories: field.otherOptions.numberOfSignatories,
              allowEdit: field.otherOptions.allowEdit,
            };
          }
          return bodyObj.items.push(tempObj);
        });
      }

      if (values.reminders) {
        bodyObj.reminders = values.reminders;
      }

      if (values.anonymousResponsesAllowed) {
        bodyObj.anonymousResponsesAllowed = values.anonymousResponsesAllowed;
      }

      for (let i = 0; i < bodyObj.items.length; i++) {
        const item = bodyObj.items[i];
        for (let j = 0; j < bodyObj.items.length; j++) {
          const innerItem = bodyObj.items[j];
          // Description and choices
          item.label = item.label.replaceAll(
            innerItem.internalId,
            innerItem.identifier
          );
          if (item.description) {
            item.description = item.description.replaceAll(
              innerItem.internalId,
              innerItem.identifier
            );
          }
          if (item.options && item.options.choices) {
            for (let k = 0; k < item.options.choices.length; k++) {
              const choice = item.options.choices[k];
              choice.label = choice.label.replaceAll(
                innerItem.internalId,
                innerItem.identifier
              );
            }
          }
        }
      }

      const signingObj = signString("PUT", uri);
      const res = await axios.put(`${uri}`, bodyObj, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      console.log(res.data);

      dispatch(getSurveysAction(values.investigation));
    } catch (error) {
      console.error(error);
      dispatch({
        type: PATCH_ERROR,
        payload:
          "Error revising form. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function deleteSurvey(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}`;
      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function postSurveyClone(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}/clone`;
      const signingObj = signString("POST", uri);
      const res = await axios.post(
        uri,
        {},
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function postSurveyFile(
  surveyId,
  itemIdentifier,
  file,
  authenticated,
  withoutItemId
) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}/file`;
      let headers = {};
      if (authenticated) {
        const signingObj = signString("POST", uri);
        headers = {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        };
      }
      let { name: filename, type: filetype, size } = file;
      if (filetype === "" || filetype === undefined) {
        filetype = filename.split(".").pop();
      }
      const res = await axios.post(
        uri,
        { itemIdentifier, filename, filetype, size, withoutItemId },
        {
          headers,
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function uploadS3Blob(
  requestParams,
  file,
  cancelToken,
  onUploadProgress
) {
  return async function (dispatch) {
    try {
      let { url } = requestParams;
      if (url.includes("localstack")) {
        url = url.replace("localstack", "localhost");
      }
      const { fields } = requestParams;

      const form = new FormData();

      Object.keys(fields).forEach((key) => {
        form.append(key, fields[key]);
      });

      form.append("file", file);

      const res = await axios({
        url,
        method: "POST",
        data: form,
        cancelToken: cancelToken.token,
        onUploadProgress,
      });
      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      dispatch(manageError(error));
      return {
        error: error,
      };
    }
  };
}

export function patchSurveyFile(fileId, authenticated) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/blob/${fileId}`;
      let headers = {};
      if (authenticated) {
        const signingObj = signString("PATCH", uri);
        headers = {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        };
      }
      const res = await axios.patch(uri, {}, { headers });
      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function getFileMetadata(fileId, includeThumbnail) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/blob/${fileId}/metadata`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      const { data } = res;
      if (!includeThumbnail) {
        return data;
      }
      if (includeThumbnail && !data.hasThumbnail) {
        return data;
      }
      const thumbnailUrl = await getFile(
        fileId,
        true,
        true,
        true,
        data.name
      )(dispatch);
      return { ...data, thumbnailUrl };
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function getFile(fileId, returnFile, authenticated, thumbnail, name) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/blob/${fileId}${
        thumbnail ? "/thumbnail" : ""
      }`;
      let headers = {};
      if (authenticated) {
        const signingObj = signString("GET", uri);
        headers = {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        };
      }
      const res = await axios({
        url: uri,
        method: "GET",
        responseType: "blob",
        headers,
      });
      const filename = name
        ? name
        : decodeURIComponent(
            res.headers["content-disposition"]
              .replace("attachment; filename=", "")
              .slice(1, -1)
          );
      const filetype = res.headers["content-type"];
      const blob = new Blob([res.data]);
      const file = new File([blob], filename, { type: filetype });
      const url = window.URL.createObjectURL(file);

      if (returnFile) {
        return url;
      }

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}

export function patchSurveyEnforcements(surveys, investigation) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/enforcement`;
      const signingObj = signString("PATCH", uri);
      let newSurveys = [];
      surveys.map((survey) => {
        let obj = {
          surveyIdentifier: survey.hexIdentifier,
          enforcements: survey.enforcements,
        };
        newSurveys.push(obj);
      });
      const bodyObj = {
        investigationId: investigation,
        surveys: newSurveys,
        revisionDate: Math.round(Date.now() / 1000),
      };
      const res = await axios.patch(`${uri}`, bodyObj, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch(getSurveysAction(investigation));
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
}
