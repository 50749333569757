import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL, WS_BACKEND_URL } from "../middleware/networking";
import axios from "axios";
import io from "socket.io-client";

export const GOT_INVESTIGATIONS = "got_investigations";
export const INVESTIGATIONS_ERROR = "investigations_error";
export const GOT_SURVEYS = "got_surveys";
export const SURVEYS_ERROR = "surveys_error";
export const GOT_EXPORT = "got_export";
export const EXPORT_ERROR = "export_error";

export function getSurveysAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/surveys`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_SURVEYS,
        payload: res.data.surveys,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SURVEYS_ERROR,
        payload:
          "Error retrieving surveys. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export const EXPORTS_ERROR = "EXPORTS_ERROR";
export const GOT_INVESTIGATION_EXPORTS = "GOT_INVESTIGATION_EXPORTS";
export const getInvestigationExports = (investigationId) => {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/exports`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GOT_INVESTIGATION_EXPORTS,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: EXPORTS_ERROR,
        payload:
          "Error retrieving exports. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};

export const INVESTIGATION_EXPORT_REQUESTED = "INVESTIGATION_EXPORT_REQUESTED";
export const INVESTIGATION_EXPORT_CREATED = "INVESTIGATION_EXPORT_CREATED";
export const postInvestigationExports = (
  investigationId,
  surveyId,
  choiceLabels,
  format
) => {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/exports`;
      const payload = {
        surveyIdentifier: surveyId,
        choiceLabels,
        format,
      };
      const signingObj = signString("POST", uri);
      const res = await axios.post(uri, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      const { identifier } = res.data;
      dispatch({
        type: INVESTIGATION_EXPORT_REQUESTED,
        payload: {
          identifier,
        },
      });

      window.userGuiding.track("segment", {
        exportCreated: true,
      });

      // TODO: continue here:
      // - Maintain a snackbar to give feedback
      const socket = io(NEW_BACKEND_URL);
      socket.on("DATA_EXPORT_CREATED", (data) => {
        console.log(`(ts) DATA_EXPORT_CREATED`, data);
        const { identifier } = data;

        if (identifier === res.data.identifier) {
          dispatch({
            type: INVESTIGATION_EXPORT_CREATED,
            payload: {
              identifier,
            },
          });
          socket.close();
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: EXPORTS_ERROR,
        payload:
          "Error creating export. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
};

export const getExport = (exportId) => {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/export/${exportId}`;
      const signingObj = signString("GET", uri);
      const res = await axios({
        url: uri,
        method: "GET",
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      const presingedUrl = res.data.presignedUrl;
      return presingedUrl;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
};
