import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Divider, Paper, Typography } from "@material-ui/core";
import TsTextField from "../shared/TsTextField";
import LightLogoPath from "../Assets/LightLogo.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { NEW_BACKEND_URL } from "../middleware/networking";
import TsIconButton from "../shared/TsIconButton";

const SignUp = ({
  classes,
  onLoginClick,
  onSubmit,
  emailId,
  appError,
  linkData,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [disableEmailField, setEmailField] = useState(false);
  const [phone, setPhone] = useState("");
  const [submitting, setSubmitting] = useState(false);
  useState(() => {
    if (!emailId || emailId === null) {
      return;
    }

    setEmail(emailId);
    setEmailField(true);
  }, [emailId]);

  useEffect(() => {
    if (appError !== null && appError.response !== undefined) {
      setSubmitting(false);
    }
  }, [appError]);

  const captchaRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const captcha = captchaRef.current.getValue();
    captchaRef.current.reset();
    onSubmit(name, email, phone, captcha);
  };
  return (
    <Paper className={classes.root}>
      <section className={classes.innerWrapper}>
        <section>
          <img
            className={classes.logo}
            src={LightLogoPath}
            alt="Studypages Data"
          />
        </section>
        {linkData && (
          <section>
            <img
              className={classes.avatar}
              src={`${NEW_BACKEND_URL}${linkData.investigationAvatar
                .replace("/mediab64", "")
                .replace("/media", "")}`}
              alt={linkData.investigationTitle}
            />
          </section>
        )}
        {linkData && (
          <section>
            <Typography variant="h1" className={classes.heading}>
              Welcome to{" "}
              <span className={classes.bold}>
                {linkData.investigationTitle}
              </span>{" "}
              on Studypages Data
            </Typography>
            {linkData.groupName && (
              <Typography variant="h4" className={classes.subheading}>
                Group: {linkData.groupName}
              </Typography>
            )}
          </section>
        )}
        <section>
          <h1 className={classes.header}>Create a new account</h1>
          <form onSubmit={handleSubmit}>
            <section className={classes.formItem}>
              <Typography variant="h4" className={classes.label}>
                Full name
              </Typography>
              <TsTextField
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={name}
                onChange={(ev) => setName(ev.target.value)}
                fullWidth
                autoFocus
              />
            </section>
            <section className={classes.formItem}>
              <Typography variant="h4" className={classes.label}>
                Email address
              </Typography>
              <TsTextField
                type="email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={email}
                disabled={disableEmailField}
                onChange={(ev) => setEmail(ev.target.value.toLowerCase())}
                fullWidth
                autoFocus
              />
            </section>
            <section className={classes.formItem}>
              <Typography variant="h4" className={classes.label}>
                Phone No (Start with country code)
              </Typography>
              <TsTextField
                type="phone"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={phone}
                onChange={(ev) => setPhone(ev.target.value)}
                fullWidth
                autoFocus
              />
            </section>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              className={classes.recaptchBottom}
            />
            <TsIconButton
              onClick={handleSubmit}
              disabled={submitting}
              loading={submitting}
              fullWidth
              noMargin
              text="Sign Up"
            />
            <section className={classes.defaultButton}></section>
            <Divider className={classes.divider} />

            <section>
              <Button
                className={classes.outlinedButton}
                variant="outlined"
                onClick={onLoginClick}
                fullWidth
              >
                Have an account? Login!
              </Button>
            </section>
          </form>
        </section>
      </section>
    </Paper>
  );
};

const styles = {
  errorToast: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: "auto",
    zIndex: 1001,
    display: "flex",
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#006ef5",
    textAlign: "center",
    color: "#fff",
    fontSize: 14,
  },
  errorIcon: {
    paddingRight: 8,
  },
  root: {
    maxWidth: 688,
    position: "fixed",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -40%)",
    width: "100%",
  },
  innerWrapper: {
    maxWidth: 688,
    padding: "40px 100px 40px 100px",
    overflow: "scroll",
    height: "90vh",
  },
  logo: {
    paddingBottom: 32,
  },
  heading: {
    maxWidth: 340,
    paddingBottom: 20,
    margin: "0 auto",
  },
  header: {
    paddingBottom: 20,
    textAlign: "center",
    maxWidth: "90vw",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 0,
    paddingTop: 20,
    marginTop: 0,
    fontSize: 28,
    fontWeight: 700,
  },
  primaryButton: {
    background: "#006ef5",
    marginTop: 20,
    // padding: "8px 0 8px 0",
  },
  recaptchBottom: {
    marginBottom: 20,
  },
  defaultButton: {
    marginTop: 20,
    // padding: "8px 0 8px 0",
  },
  outlinedButton: {
    // padding: "8px 0 8px 0",
  },
  divider: {
    margin: "40px auto",
    maxWidth: 100,
  },
  label: {
    paddingBottom: 4,
  },
  formItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  notice: {},
  noticeIcon: {
    fontSize: 40,
  },
  noticeHeading: {
    paddingTop: 12,
    paddingBottom: 4,
    fontSize: 20,
  },
  noticeDescription: {
    maxWidth: 360,
    paddingBottom: 40,
    margin: "0 auto",
    color: "#575e66",
  },
  avatar: {
    width: 80,
    margin: "20px 0 12px 0",
    border: "1px solid #d9dee4",
    borderRadius: 4,
  },
  divider: {
    margin: "40px auto",
    maxWidth: 100,
  },
  subheading: {
    color: "#828e99",
    fontWeight: 400,
    textAlign: "center",
  },
};

export default withStyles(styles)(SignUp);
