import { manageError } from "./errors";

import signString from "../middleware/signatureSigning";
import {
  NEW_BACKEND_URL,
  NODE_ENV,
  FRONTEND_BASE_URL,
} from "../middleware/networking";

import axios from "axios";

export const AUTHENTICATED = "authenticated_user";
export const UNAUTHENTICATED = "unauthenticated_user";
export const AUTHENTICATION_ERROR = "authentication_error";

export function signInAction(
  { email, password, code, accessToken, token, groupIdentifier },
  fromTsSignIn
) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/keys`;
      let res;
      if (code) {
        try {
          res = await axios.post(`${NEW_BACKEND_URL}/keys/oauth`, {
            code,
            frontendFlag: NODE_ENV === "staging" ? 3 : 1,
            localHostUrl:
              NODE_ENV === "staging" ? FRONTEND_BASE_URL : undefined,
            token,
            groupIdentifier,
          });
          if (res.data.location) {
            return {
              location: res.data.location,
            };
          }
        } catch (error) {
          return {
            status: 404,
          };
        }
      } else if (accessToken) {
        try {
          res = await axios.post(`${NEW_BACKEND_URL}/keys/oauth`, {
            accessToken,
            frontendFlag: NODE_ENV === "staging" ? 3 : 1,
            localHostUrl:
              NODE_ENV === "staging" ? FRONTEND_BASE_URL : undefined,
          });
        } catch (error) {
          return {
            status: 404,
          };
        }
      } else {
        res = await axios.post(
          uri,
          {
            frontendClient: true,
          },
          {
            auth: {
              username: email.toLowerCase(),
              password: password,
            },
          }
        );
      }

      if (
        res.data &&
        (res.data.registeredOnStudyPages === true ||
          res.data.registeredOnStudyPages === false)
      ) {
        if (!fromTsSignIn) {
          dispatch({
            type: AUTHENTICATED,
          });
        }
        return res.data;
      }

      const detailsUri = `${NEW_BACKEND_URL}/user/${
        res.data.user.split("/")[2]
      }/details`;
      const signingObj = signString(
        "GET",
        detailsUri,
        res.data.uri,
        res.data.data
      );
      const detailsRes = await axios.get(detailsUri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      res.data.validDate = new Date().getTime() + 60 * 60 * 1000;
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("userEmail", email ? email : res.data.email);
      localStorage.setItem("userData", res.data.data);
      localStorage.setItem("userHex", res.data.user.split("/")[2]);
      if (res.data.uri) {
        localStorage.setItem("userUri", res.data.uri.split("/")[2]);
      }
      if (res.data.unscrambledUser) {
        localStorage.setItem(
          "unscrambledUser",
          res.data.unscrambledUser.split("/")[3]
        );
      }
      localStorage.setItem("userB64", detailsRes.data.userId);

      if (detailsRes.data.avatar) {
        localStorage.setItem("userAvatar", detailsRes.data.avatar);
      }
      if (detailsRes.data.name) {
        localStorage.setItem("userName", detailsRes.data.name);
      }
      if (detailsRes.data.jobTitle) {
        localStorage.setItem("userJob", detailsRes.data.jobTitle);
      }

      window.analytics.identify(detailsRes.data.userId, {
        email,
        name: detailsRes.data.name,
      });

      // Tawk.to identification
      /*
      const { Tawk_API: tawkToApi } = window;
      const { REACT_APP_TAWK_TO_API_KEY: tawkToApiKey } = process.env;
      if (tawkToApi && tawkToApiKey) {
        const hash = CryptoJS.HmacSHA256(email, tawkToApiKey).toString(
          CryptoJS.enc.Hex
        );
        tawkToApi.setAttributes(
          {
            name: detailsRes.data.name,
            email,
            hash,
          },
          (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        );
      }
      */

      dispatch({
        type: AUTHENTICATED,
      });
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid email or password",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

export function signUpAction({
  name,
  email,
  phone,
  captcha,
  token,
  groupIdentifier,
  investigationIdentifier,
}) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/user`;
      let res;
      res = await axios.post(uri, {
        email: email.toLowerCase(),
        names: name,
        phone,
        "g-recaptcha-response": captcha,
        token,
        groupIdentifier,
        investigationIdentifier,
      });
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid data",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

export function getBlogAnouncement() {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/blog`;
      let res = await axios.get(uri);
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid data",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

export function checkUserEmail({ email }) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/user/checkEmail?email=${encodeURIComponent(
        email.toLowerCase()
      )}`;
      let res;
      res = await axios.get(uri);
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid data",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

export function studyPagesSignUp({ email, username, phone, userId }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${NEW_BACKEND_URL}/user/${userId}/studypages`,
        {
          name: username,
          email: email.toLowerCase(),
          phone,
        }
      );
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(manageError(error));
      return false;
    }
  };
}

export function verifyEmail(userIdentifier, token) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/email-verification`;
      const res = await axios.post(uri, {
        userIdentifier,
        token,
      });

      const detailsUri = `${NEW_BACKEND_URL}/user/${
        res.data.user.split("/")[2]
      }/details`;
      const signingObj = signString(
        "GET",
        detailsUri,
        res.data.uri,
        res.data.data
      );
      const detailsRes = await axios.get(detailsUri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      res.data.validDate = new Date().getTime() + 60 * 60 * 1000;
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("userEmail", detailsRes.data.email.toLowerCase());
      localStorage.setItem("userHex", res.data.user.split("/")[2]);
      localStorage.setItem("userB64", detailsRes.data.userId);

      if (detailsRes.data.avatar) {
        localStorage.setItem("userAvatar", detailsRes.data.avatar);
      }
      if (detailsRes.data.name) {
        localStorage.setItem("userName", detailsRes.data.name);
      }
      if (detailsRes.data.jobTitle) {
        localStorage.setItem("userJob", detailsRes.data.jobTitle);
      }

      window.analytics.identify(detailsRes.data.userId, {
        email: detailsRes.data.email,
        name: detailsRes.data.name,
      });

      dispatch({
        type: AUTHENTICATED,
      });
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid email or password",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

export function resendEmailVerificationLink(email) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/resend-email-verification-email`;
      const res = await axios.post(uri, {
        email,
      });
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid email or password",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

// export function refreshAction() {
//   return async (dispatch) => {
//     try {
//       let uri;
//       if (process.env.NODE_ENV === 'production') {
//         if (window.location.host.includes('staging')) {
//           uri = 'https://staging-api.teamscope.co/jwtrefresh'
//         } else {
//           uri = 'https://api.teamscope.co/jwtrefresh'
//         }
//       } else {
//         uri = 'https://staging-api.teamscope.co/jwtrefresh'
//       }

//       const res = await axios.patch(`${uri}`, {
//         email: localStorage.getItem('userEmail'),
//         token: localStorage.getItem('user'),
//         refreshToken: localStorage.getItem('userRefresh')
//       });

//       localStorage.setItem('user', res.data.token);
//       localStorage.setItem('userRefresh', res.data.refreshToken)

//       return new Promise((resolve, reject) => {
//         dispatch({
//           type: AUTHENTICATED
//         })
//       })

//     } catch (error) {
//       dispatch({
//         type: AUTHENTICATION_ERROR,
//         payload: ''
//       });
//     }
//   };
// }

export function signOutAction() {
  localStorage.removeItem("user");
  localStorage.removeItem("userRefresh");
  localStorage.removeItem("userHex");
  localStorage.removeItem("userB64");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userAvatar");
  localStorage.removeItem("userName");
  localStorage.removeItem("userJob");
  return {
    type: UNAUTHENTICATED,
  };
}

export function checkAuth() {
  return (dispatch) => {
    let userData = localStorage.getItem("user");
    try {
      JSON.parse(userData);
    } catch (e) {
      localStorage.removeItem("user");
      userData = null;
    }
    if (userData && JSON.parse(userData).validDate > new Date().getTime()) {
      console.log("AUTH");
      return dispatch({
        type: AUTHENTICATED,
      });
    } else {
      console.log("UNAUTH");
      localStorage.removeItem("user");
      return dispatch({
        type: UNAUTHENTICATED,
      });
    }
  };
}

export function getEConsentLinkData(url) {
  return async (dispatch) => {
    try {
      const userIdentifier = getParameterByName("userIdentifier", url);
      const token = getParameterByName("token", url);
      const uri = `${NEW_BACKEND_URL}/user/${userIdentifier}/eConsent/${token}`;
      let res;
      res = await axios.get(uri);
      return res.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: "Invalid data",
      });
      dispatch(manageError(error));
      return false;
    }
  };
}

function getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
