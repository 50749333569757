import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,
} from "../actions/signIn";

export default function (state = {}, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        authenticated: true,
      });
    case UNAUTHENTICATED:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        authenticated: false,
      });
    case AUTHENTICATION_ERROR:
      return Object.assign({}, JSON.parse(JSON.stringify(state)), {
        error: action.payload,
      });
  }
  return state;
}
