import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.css";
import { theme } from "./muiTheme";

import App from "./App";

// import Branch from "branch-sdk";

Sentry.init({
  dsn: "https://65831ac0c7ea43f2b02fe64f23dda5bc@o22051.ingest.sentry.io/5565041",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// Branch.init(process.env.REACT_APP_BRANCH_API_KEY);

// eslint-disable-next-line no-extend-native
Object.defineProperties(Array.prototype, {
  countStatus: {
    value: function (status) {
      /* 
			   Counts number of occurences of a specific type of entry (archived or unarchived) in an array.
			*/
      let count = 0;
      for (let i = 0; i < this.length; i++)
        if (this[i].status === status) count++;
      return count;
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);
