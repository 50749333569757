import { manageError } from "./errors";
import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";
import { ACTIVITY_PER_PAGE } from "../Dashboard/Activity/ActivityMonitorContainer";

const qs = require("querystring-browser");
const __ = window.async;

export const GOT_ACTIVITIES = "GOT_ACTIVITIES";
export const ACTIVITIES_ERROR = "ACTIVITIES_ERROR";

export function getInvestigationActivitiesAction(
  investigationIdentifier,
  page
) {
  return async (dispatch) => {
    try {
      let uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/activity?limit=${ACTIVITY_PER_PAGE}`;
      if (page) {
        uri += `&page=${page}`;
      }
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_ACTIVITIES,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACTIVITIES_ERROR,
        payload:
          "Error retrieving audits. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
