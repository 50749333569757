import { GOT_QUERIES } from "../actions/queries";
import {
  SAVED_INVESTIGATION,
  INVESTIGATION_LOOKUP,
} from "../actions/dashboard";

const initialState = {
  graphData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVED_INVESTIGATION:
      return {
        ...state,
        savedInvest: action.payload,
      };
    case INVESTIGATION_LOOKUP:
      return {
        ...state,
        investLookup: action.payload,
      };
    case GOT_QUERIES:
      return {
        ...state,
        queryData: action.payload,
      };
  }
  return state;
}
