import axios from "axios";

import {
  NEW_BACKEND_URL,
  BRANCH_API_KEY,
  BRANCH_URL,
} from "../middleware/networking";
import { manageError } from "./errors";

export const DEEP_LINK_READ = "DEEP_LINK_READ";
export const DEEP_LINK_DATA_READ = "DEEP_LINK_DATA_READ";

export const getDeepLink =
  (token, investigationIdentifier, groupIdentifier) => async (dispatch) => {
    try {
      let uri;
      if (investigationIdentifier) {
        uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/link/${token}`;
      } else if (groupIdentifier) {
        uri = `${NEW_BACKEND_URL}/group/${groupIdentifier}/link/${token}`;
      }
      const res = await axios.get(uri);
      const {
        data: { link: payload },
      } = res;

      dispatch({
        type: DEEP_LINK_READ,
        payload,
      });

      return await getDeepLinkData(payload)(dispatch);
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };

export const getDeepLinkData = (link) => async (dispatch) => {
  try {
    const uri = `${BRANCH_URL}/url?url=${link}&branch_key=${BRANCH_API_KEY}`;
    const res = await axios.get(uri);
    const {
      data: { data: payload },
    } = res;
    dispatch({
      type: DEEP_LINK_DATA_READ,
      payload,
    });
    return { payload, link };
  } catch (error) {
    console.error(error);
    dispatch(manageError(error));
  }
};
