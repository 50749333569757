import { reset } from "redux-form";
import { manageError } from "./errors";

import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");

export const GOT_INVESTIGATIONS = "got_investigations";
export const INVESTIGATIONS_ERROR = "investigations_error";
export const GOT_PERMISSIONS = "got_permissions";
export const PERMISSIONS_ERROR = "permissions_error";
export const SAVED_INVESTIGATION = "saved_investigation";
export const SAVED_TITLE = "saved_title";
export const INVESTIGATION_LOOKUP = "investigation_lookup";
export const GOT_AVATAR = "got_avatar";
export const AVATAR_ERROR = "avatar_error";
export const GOT_INVEST_AVATAR = "got_invest_avatar";
export const INVEST_AVATAR_ERROR = "invest_avatar_error";
export const SAVED_INVEST_DETAILS = "saved_invest_details";
export const INVEST_DETAILS_ERROR = "invest_details_error";

export function getInvestigationsAction() {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation`;
      const signingObj = signString("GET", uri);
      console.log(signingObj.string);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      let investLookup = [];
      res.data.investigations.forEach((e) => {
        let tempObj = {};
        tempObj.hexIdentifier = e.uri.substr(14);
        tempObj.b64Identifier = e.b64Identifier;
        investLookup.push(tempObj);
      });

      dispatch({
        type: INVESTIGATION_LOOKUP,
        payload: investLookup,
      });

      dispatch({
        type: GOT_INVESTIGATIONS,
        payload: res.data.investigations,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATIONS_ERROR,
        payload:
          "Error retrieving investigations. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export const getInvestigation = (identifier) => async (dispatch) => {
  try {
    const uri = `${NEW_BACKEND_URL}/investigation/${identifier}`;
    const signingObj = signString("GET", uri);
    const res = await axios.get(uri, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });
    return res.data.details;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(manageError(error));
  }
};

export function getUserAvatar(avatarURL) {
  // used in usersettings screen and the sidebar bottom
  if (!avatarURL) {
    return async (dispatch) => {
      dispatch({
        type: AVATAR_ERROR,
      });
      const error = {
        message: "Misssing Avatar",
      };
      dispatch(manageError(error));
    };
  }
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}${avatarURL.replace(
      "/mediab64",
      ""
    )}/base64`;
    axios
      .get(uri)
      .then((response) => {
        dispatch({
          type: GOT_AVATAR,
          payload: response.data,
        });
      })
      .catch((err) => {
        if (err) {
          console.error(err);
          dispatch({
            type: AVATAR_ERROR,
          });
          dispatch(manageError(err));
        }
      });
  };
}

export function downloadUserAvatar(avatarURL) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}${avatarURL.replace(
      "/mediab64",
      ""
    )}/base64`;
    return axios
      .get(uri)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err) {
          console.error(err);
        }
      });
  };
}

export function getInvestigationPermissionsAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/permissions`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      try {
        const { manageOrganization } = res.data.permissions;
        window.userGuiding.track("segment", {
          manageOrganization,
        });
      } catch (e) {
        console.error(`error`, e);
      }
      dispatch({
        type: GOT_PERMISSIONS,
        payload: res.data.permissions,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: PERMISSIONS_ERROR,
        payload:
          "Error retrieving permissions. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getAnonymousInvestigationPermissions(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/permissions`;
      const res = await axios.get(uri);
      dispatch({
        type: GOT_PERMISSIONS,
        payload: res.data.permissions,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: PERMISSIONS_ERROR,
        payload:
          "Error retrieving permissions. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export const GOT_INVESTIGATION_BILLING = "GOT_INVESTIGATION_BILLING";
export const INVESTIGATION_BILLING_ERROR = "GOT_INVESTIGATION_BILLING_ERROR";
export function getInvestigationBilling(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/billing`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_INVESTIGATION_BILLING,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATION_BILLING_ERROR,
        payload:
          "Error retrieving investigation billing. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getAnonymousInvestigationBilling(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/billing`;
      const res = await axios.get(uri);
      dispatch({
        type: GOT_INVESTIGATION_BILLING,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: INVESTIGATION_BILLING_ERROR,
        payload:
          "Error retrieving investigation billing. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getInvestigationAvatar(avatarURL) {
  return async (dispatch) => {
    const uri = `${NEW_BACKEND_URL}${avatarURL.replace(
      "/mediab64",
      ""
    )}/base64`;
    axios
      .get(`${uri}`)
      .then((response) => {
        dispatch({
          type: GOT_INVEST_AVATAR,
          payload: response.data,
        });
      })
      .catch((err) => {
        if (err) {
          console.error(err);
          dispatch({
            type: INVEST_AVATAR_ERROR,
          });
          dispatch(manageError(err));
        }
      });
  };
}

export function saveInvestigationAction(invest) {
  return (dispatch, getState) => {
    try {
      let investArr = invest.split("|");
      let investIdentifier = investArr.splice(0, 1)[0];
      let title = investArr.join("|");
      dispatch({
        type: SAVED_INVESTIGATION,
        payload: investIdentifier,
      });
      dispatch({
        type: SAVED_TITLE,
        payload: title,
      });
    } catch (error) {
      console.error("SAVED_INVESTIGATION ERROR");
      console.error(error);
    }
  };
}

export function removeInvestigationsAction() {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: SAVED_INVESTIGATION,
        payload: undefined,
      });
      dispatch({
        type: "got_cases",
        payload: undefined,
      });
    } catch (error) {
      console.error("REMOVE_INVESTIGATIONS ERROR");
      console.error(error);
    }
  };
}

export function saveInvestDetailsAction(invests, currentInvest) {
  return (dispatch) => {
    let investArr = currentInvest ? currentInvest.split("|") : null;
    let investIdentifier = investArr ? investArr.splice(0, 1)[0] : null;

    let invest;
    if (invests && investIdentifier) {
      invest = invests.find((e) => {
        return e.uri.includes(investIdentifier);
      });
    }

    if (invest) {
      if (invest.avatar) {
        dispatch(
          getInvestigationAvatar(invest.avatar.replace("/mediab64", ""))
        );
      }
      dispatch({
        type: SAVED_INVEST_DETAILS,
        payload: invest,
      });
    }
  };
}

export function resetFormsAction(formName) {
  return async (dispatch) => {
    dispatch(reset(formName));
  };
}
