import {
  UPDATED_USER,
  UPDATED_USER_ERROR,
  USER_PASSWORD_FAIL,
  USER_EMAIL_CHANGED,
  USER_EMAIL_ERROR,
  USER_EMAIL_FAIL,
  USER_PASSWORD_CHANGED,
  USER_PASSWORD_ERROR,
} from "../actions/userManagement";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_EMAIL_FAIL:
      return {
        ...state,
        emailError: action.payload,
      };
    case USER_EMAIL_CHANGED:
      return {
        ...state,
        emailError: undefined,
        emailSuccess: action.payload,
      };
    case USER_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };
    case USER_PASSWORD_FAIL:
      return {
        ...state,
        passwordError: action.payload,
      };
    case USER_PASSWORD_CHANGED:
      return {
        ...state,
        passwordError: undefined,
        passwordSuccess: action.payload,
      };
    case USER_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.payload,
      };
  }
  return state;
}
