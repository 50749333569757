import { PRISTINE, NAV_LINK_CLICK } from "../actions/pristine";

export default function (
  state = { pristine: true, navLinkClicked: false },
  action
) {
  switch (action.type) {
    case PRISTINE: {
      const { pristine } = action;
      return { ...state, pristine };
    }
    case NAV_LINK_CLICK: {
      const { navLinkPath, navLinkClicked } = action;
      return { ...state, navLinkPath, navLinkClicked };
    }
    default:
      return state;
  }
}
