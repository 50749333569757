import { GOT_INVESTIGATION_EXPORTS } from "../actions/export";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GOT_INVESTIGATION_EXPORTS:
      return action.payload;
    default:
      return state;
  }
}
