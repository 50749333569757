import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";

import AutosizeInput from "react-input-autosize";

import TsIconButton from "../TsIconButton";
import UnstyledTsTextField from "../TsCustomisableTextField";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";

const TsTextField = withStyles((theme) => ({
  root: {
    borderRadius: 6,
    border: "3px solid transparent !important",
    "& fieldset": {
      border: "1px solid transparent !important",
    },
    backgroundColor: "transparent",
  },
  focused: {
    border: "3px solid #7daff7 !important",
    boxShadow: "0 0 0 0 #7daff7",
  },
  input: {
    padding: "10px 4px",
  },
}))(UnstyledTsTextField);

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "left",
    marginBottom: 40,
    maxWidth: 1100,
  },
  headerMetadata: {
    flexGrow: 1,
    marginRight: 10,
    maxWidth: "70%",
    marginTop: -8,
    // display: "flex",
  },
  headerEditableContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: -4,
  },
  headerInputOuter: {
    maxWidth: "100%",
    marginRight: 10,
  },
  headerInput: {
    maxWidth: "100%",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#272A2E",
    border: "1px solid transparent",
    backgroundColor: "transparent",
    borderRadius: 4,
    wordWrap: "break-word",
    wordBreak: "break-all",
    padding: "8px 4px",
  },
  headerInputEnabledForCases: {
    maxWidth: "100% !important",
    fontSize: "24px",
    color: "#272A2E",
    fontWeight: "bold",
    backgroundColor: "transparent",
    borderRadius: 4,
    wordWrap: "break-word",
    wordBreak: "break-all",
    padding: "8px 4px",
    minWidth: "320px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "&:focus": {
      border: "1px solid #006ef5 !important",
      boxShadow: "0 0 0 4px #bfdcff",
    },
    outlineStyle: "none",
  },
  headerInputEnabled: {
    maxWidth: "100% !important",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#272A2E",
    border: "1px solid transparent",
    backgroundColor: "transparent",
    borderRadius: 4,
    wordWrap: "break-word",
    wordBreak: "break-all",
    padding: "8px 4px",
    "&:hover": {
      border: "1px solid #828e99",
    },
  },
  leadInputOuter: {
    marginTop: 1,
    marginLeft: -4,
  },
  leadInput: {
    maxWidth: "500px",
    // marginLeft: "-4px",
    fontSize: "14px",
    lineHeight: "20%",
    fontWeight: 400,
  },
  headerText: {
    margin: 0,
    fontSize: "1.5em",
  },
  headerLead: {
    color: "#333333",
    margin: "0 auto",
    fontSize: 14,
    lineHeight: "38px",
    fontWeight: "400",
  },
  iconButton: {
    height: 36,
    width: 36,
    fontSize: 20,
    marginRight: 5,
  },
  saveIconButton: {
    color: "#1ec2ad",
    backgroundColor: "#dff7f4",
    "&:hover": {
      color: "#189988",
      backgroundColor: "#cfe6e3",
    },
  },
  discardIconButton: {
    color: "#828e99",
    backgroundColor: "#ebeff2",
    "&:hover": {
      backgroundColor: "#ffe5e8",
      color: "#ea3546",
    },
  },
  flex: {
    display: "flex",
  },
  headerPrimaryContent: {
    "& > section": {
      display: "flex",
    },
    /*
    display: "flex",
    alignItems: "center",
    */
  },
  progress: {
    marginLeft: "12px",
  },
  noMargin: {
    margin: "0 !important",
  },
  badge: {
    color: "#fff",
    margin: "4px 0 0 8px",
    textAlign: "center",
    padding: "2px 6px 2px 6px",
    borderRadius: 4,
    letterSpacing: 2,
    maxHeight: "24px",
    fontSize: "14px",
    textTransform: "uppercase",
    backgroundColor: "#354a4c",
    fontWeight: 700,
  },
  editButton: {
    padding: "5px 0 5px 0",
  },
  primaryButton: {
    background: "#006ef5",
    padding: "5px 0 5px 0",
  },
  cancelButton: {
    padding: "4px 0 4px 0",
    marginLeft: 16,
  },
};

const TsDashboardHeader = ({
  classes,
  className,
  placeholder,
  name,
  disabled,
  text,
  badge,
  lead,
  leadName,
  leadPlaceholder,
  buttonText,
  buttonIcon,
  secondaryButtonText,
  secondaryButtonIcon,
  secondaryButtonProps,
  editable,
  editPermission,
  instantly,
  autoFocus,
  noMargin,
  purpleButton,
  children,
  loading,
  busy,
  onSubmit,
  onChange,
  onLeadChange,
  onButtonClick,
  onSecondaryButtonClick,
  caseResponsesScreen,
  onBlur,
}) => {
  const [currValue, setCurrValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [caseLabelDisabled, setDisabled] = useState(false);
  const [hovering, setHovering] = useState(false);
  let inputRef = useRef(null);

  useEffect(() => {
    if (!placeholder) {
      setCurrValue(text);
      setInputValue(text);
    }
    if (caseResponsesScreen) setDisabled(true);
    setDescription(lead);
  }, [caseResponsesScreen, placeholder, text, inputRef]);

  const handleFocus = () => {
    setDisabled(false);
    setHovering(false);
    setFocused(true);
    setTimeout(() => inputRef.current.focus(), 1);
  };

  const handleSubmit = () => {
    setFocused(false);
    if (inputValue.length > 0) {
      onSubmit(inputValue);
      setCurrValue(inputValue);
    }
    setDisabled(true);
  };

  const handleDiscard = () => {
    setFocused(false);
    setInputValue(currValue);
    setDisabled(true);
  };

  const handleClickAway = () => {
    if (!caseResponsesScreen) setFocused(false);
  };

  const [description, setDescription] = useState("");

  const onLocalBlur = (ev) => {
    if (onBlur) {
      onBlur(ev);
    }
  };

  return (
    <section
      className={classNames(classes.headerContainer, className, {
        [classes.noMargin]: noMargin,
      })}
    >
      <div className={classes.headerMetadata}>
        <div className={classes.headerPrimaryContent}>
          <section>
            {editable ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <section
                  className={classes.headerEditableContainer}
                  onMouseEnter={() => {
                    if (!focused) setHovering(true);
                  }}
                  onMouseLeave={() => setHovering(false)}
                >
                  <AutosizeInput
                    className={classes.headerInputOuter}
                    name={name}
                    inputClassName={
                      caseLabelDisabled
                        ? classes.headerInput
                        : caseResponsesScreen
                        ? classes.headerInputEnabledForCases
                        : classes.headerInputEnabled
                    }
                    value={instantly ? text : inputValue}
                    placeholder={
                      instantly ? placeholder : placeholder ? text : null
                    }
                    onChange={(ev) => {
                      if (instantly) {
                        onChange(ev);
                      } else {
                        setInputValue(ev.target.value);
                      }
                    }}
                    onBlur={onLocalBlur}
                    autoFocus={autoFocus}
                    disabled={disabled || caseLabelDisabled}
                    placeholderIsMinWidth
                    inputRef={(e) => {
                      inputRef.current = e;
                    }}
                  />
                  {(loading || busy || disabled) && (
                    <CircularProgress className={classes.progress} size={24} />
                  )}
                  {hovering && caseResponsesScreen && (
                    <Tooltip
                      title={
                        !editPermission
                          ? "Blocked by study admin, please contact them"
                          : ""
                      }
                      enterDelay={100}
                    >
                      <span>
                        <Button
                          onClick={handleFocus}
                          style={{ borderColor: "transparent" }}
                          variant="outlined"
                          color="primary"
                          className={classes.editButton}
                          disabled={!editPermission}
                        >
                          Edit
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {focused && !instantly && (
                    <>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="defualt"
                        className={classes.cancelButton}
                        onClick={handleDiscard}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </section>
              </ClickAwayListener>
            ) : (
              <div className={classes.headerEditableContainer}>
                <h1 className={classes.headerText}>{text}</h1>
                {(loading || busy || disabled) && (
                  <CircularProgress className={classes.progress} size={24} />
                )}
              </div>
            )}
            {badge && <span className={classes.badge}>{badge}</span>}
          </section>
          {lead !== undefined &&
            (editable && !caseResponsesScreen ? (
              <TsTextField
                className={classNames(
                  classes.headerInputOuter,
                  classes.leadInputOuter
                )}
                type="text"
                name={leadName}
                putClassName={classNames(
                  classes.headerInput,
                  classes.leadInput
                )}
                value={description}
                change={(ev) => {
                  setDescription(ev.target.value);
                }}
                onBlur={(ev) => {
                  if (lead !== ev.target.value) {
                    onLeadChange(ev);
                  }
                }}
                placeholder={leadPlaceholder}
                multiline
              />
            ) : (
              <h2 className={classes.headerLead}>{lead}</h2>
            ))}
        </div>
      </div>
      {!loading && (
        <>
          {children ? (
            <section className={classes.flex}>{children}</section>
          ) : (
            <>
              {(secondaryButtonText || secondaryButtonIcon) && (
                <TsIconButton
                  text={secondaryButtonText}
                  icon={secondaryButtonIcon}
                  onClick={onSecondaryButtonClick}
                  variant="outlined"
                  color="default"
                  isNew={secondaryButtonProps.isNew}
                  disabled={busy}
                  {...secondaryButtonProps}
                />
              )}
              {(buttonText || buttonIcon) && (
                <TsIconButton
                  text={buttonText}
                  icon={buttonIcon}
                  onClick={onButtonClick}
                  style={purpleButton && { backgroundColor: "#4e1f99" }}
                  disabled={busy}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default withStyles(styles)(TsDashboardHeader);
