import { manageError } from "./errors";

import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";
import axios from "axios";

const qs = require("querystring-browser");

export const GOT_SURVEYS = "got_surveys";
export const SURVEYS_ERROR = "surveys_error";
export const GOT_SURVEY = "got_survey";
export const SURVEY_ERROR = "survey_error";
export const SAVE_ERROR = "survey_error";
export const RESET_GRAPHS = "reset_graphs";
export const GOT_GRAPH = "got_graph";
export const GRAPH_ERROR = "graph_error";
export const SAVED_GRAPH = "saved_graph";
export const DELETED_GRAPH = "deleted_graph";
export const DELETE_ERROR = "delete_error";
export const RESET_PROPS = "reset_props";

export function resetPropsAction() {
  return async (dispatch) => {
    dispatch({
      type: RESET_PROPS,
    });
  };
}

export function getSurveysAction(investigationId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationId}/surveys`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_SURVEYS,
        payload: res.data.surveys,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SURVEYS_ERROR,
        payload:
          "Error retrieving surveys. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function deleteGraphsAction(identifier) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/graph/${identifier}`;
      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return dispatch({
        type: DELETED_GRAPH,
        payload: identifier,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_ERROR,
        payload:
          "Error retrieving survey. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getSurveyAction(surveyId) {
  return async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/survey/${surveyId}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: GOT_SURVEY,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SURVEY_ERROR,
        payload:
          "Error retrieving survey. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getSavedGraphsAction(investigation) {
  return async (dispatch, getState) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigation}/graph`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      dispatch({
        type: RESET_GRAPHS,
      });

      res.data.forEach((graph) => {
        let tempValues = {};
        tempValues = graph.values;
        tempValues.identifier = graph.identifier;
        tempValues.timestamp = graph.timestamp;
        dispatch(getGraphAction(graph.identifier, tempValues));
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SAVE_ERROR,
        payload:
          "Error retrieving survey. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function saveGraphAction(values) {
  return async (dispatch) => {
    try {
      let tempIdentifier = values.identifier;
      let queryObj = {
        survey: values.survey,
        ...(values.field && {
          field: values.field,
        }),
        chartType: values.chartType,
        investigation: values.investigation,
        ...(values.dateType && {
          dateType: values.dateType,
        }),
        ...(values.dateUnit && {
          dateUnit: values.dateUnit,
        }),
        ...(values.dateUnit && {
          dateUnitSelection: values.dateUnitSelection,
        }),
        ...(values.dateSelectionStart && {
          dateSelectionStart: values.dateSelectionStart,
        }),
        ...(values.dateSelectionEnd && {
          dateSelectionEnd: values.dateSelectionEnd,
        }),
      };

      const uri = `${NEW_BACKEND_URL}/investigation/${values.investigation}/graph`;
      const signingObj = signString("POST", uri);
      const res = await axios.post(
        `${uri}`,
        {
          values: queryObj,
        },
        {
          headers: {
            "X-Date": signingObj.date,
            Authorization: signingObj.string,
          },
        }
      );

      values.identifier = res.headers.location;
      values.timestamp = res.headers["X-Identifier-Time"];

      console.log(`values: `, res);

      if (values.edit === true) {
        dispatch(deleteGraphsAction(tempIdentifier));
        dispatch(getGraphAction(res.headers.location, values));
        dispatch({
          type: SAVED_GRAPH,
        });
      } else {
        dispatch(getGraphAction(res.headers.location, values));
        dispatch({
          type: SAVED_GRAPH,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: SAVE_ERROR,
        payload:
          "Error retrieving survey. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}

export function getGraphAction(identifier, values) {
  return async (dispatch, getState) => {
    try {
      let queryObj = {
        surveyIdentifier: values.survey,
        ...(values.field && {
          fieldIdentifier: values.field,
        }),
        chartType: values.chartType,
        investIdentifier: values.investigation,
        ...(values.dateType && {
          dateType: values.dateType,
        }),
        ...(values.dateUnit && {
          dateUnit: values.dateUnit,
        }),
        ...(values.dateUnit && {
          dateUnitSelection: values.dateUnitSelection,
        }),
        ...(values.dateSelectionStart && {
          dateSelectionStart: values.dateSelectionStart,
        }),
        ...(values.dateSelectionEnd && {
          dateSelectionEnd: values.dateSelectionEnd,
        }),
      };
      const uri = `${NEW_BACKEND_URL}/graph/${identifier}`;
      const signingObj = signString("GET", uri);
      const res = await axios.get(`${uri}`, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });

      console.log(values);

      let config;

      switch (values.chartType) {
        case "bar":
          config = {
            chart: {
              type: "bar",
              spacingTop: 80,
              spacingLeft: 30,
              spacingRight: 30,
              shadow: {
                color: "black",
                opacity: 0.2,
                width: 2,
                offsetX: -0.2,
              },
            },
            subtitle: {
              text: res.data.surveyTitle,
              align: "Left",
              x: 20,
              y: -50,
              floating: true,
            },
            title: {
              text: res.data.fieldName,
              align: "Left",
              x: 20,
              y: -25,
              floating: false,
            },
            xAxis: {
              categories: [],
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Frequency",
              },
              labels: {
                overflow: "justify",
              },
              tickInterval: 1,
              endOnTick: false,
            },
            tooltip: {},
            legend: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                data: [],
              },
            ],
          };

          for (var key in res.data.choices) {
            if (res.data.choices.hasOwnProperty(key)) {
              config.xAxis.categories.push(res.data.choices[key].label);
              config.series[0].data.push(res.data.choices[key].count);
            }
          }
          break;
        case "frequency":
          config = {
            chart: {
              type: "line",
              spacingTop: 100,
              spacingLeft: 30,
              spacingRight: 30,
              shadow: {
                color: "black",
                opacity: 0.2,
                width: 2,
                offsetX: -0.2,
              },
            },
            subtitle: {
              text: res.data.surveyTitle,
              align: "Left",
              x: 20,
              y: -70,
              floating: true,
            },
            title: {
              text: "Frequency of entries over time",
              align: "Left",
              x: 20,
              y: -45,
              floating: true,
            },
            yAxis: {
              title: {
                text: "Frequency",
              },
            },
            xAxis: {
              type: "datetime",
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              margin: 20,
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              },
            },

            series: [],
            credit: {
              enabled: false,
            },

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          for (var key in res.data) {
            if (res.data.hasOwnProperty(key)) {
              let tempObj = {};
              tempObj.name = res.data[key].name;
              tempObj.data = [];
              res.data[key].dates.forEach((date) => {
                tempObj.data.push([Date.parse(date.day), date.count]);
              });
              config.series.push(tempObj);
            }
          }
          break;
        case "line":
          config = {
            chart: {
              spacingTop: 80,
              spacingLeft: 30,
              spacingRight: 30,
              shadow: {
                color: "black",
                opacity: 0.2,
                width: 2,
                offsetX: -0.2,
              },
            },
            subtitle: {
              text: res.data.surveyTitle,
              align: "Left",
              x: 20,
              y: -50,
              floating: true,
            },
            title: {
              text: res.data.fieldName,
              align: "Left",
              x: 20,
              y: -25,
              floating: false,
            },
            xAxis: {
              type: "datetime",
              title: {
                text: "Date",
              },
            },
            legend: {
              enabled: false,
              margin: 20,
            },
            yAxis: {
              title: {
                text: "Amount",
              },
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              },
            },
            credit: {
              enabled: false,
            },
            series: [
              {
                name: "Field",
                data: [],
              },
            ],
          };

          res.data.data.sort((a, b) => {
            return Date.parse(a.timestamp) - Date.parse(b.timestamp);
          });

          res.data.data.forEach((value) => {
            config.series[0].data.push([
              Date.parse(value.timestamp),
              parseInt(value.value, 10),
            ]);
          });
          break;
        case "lineChoice":
          config = {
            chart: {
              spacingTop: 100,
              spacingLeft: 30,
              spacingRight: 30,
              shadow: {
                color: "black",
                opacity: 0.2,
                width: 2,
                offsetX: -0.2,
              },
            },
            subtitle: {
              text: res.data.surveyTitle,
              align: "Left",
              x: 20,
              y: -50,
              floating: true,
            },
            title: {
              text: res.data.fieldName,
              align: "Left",
              x: 20,
              y: -25,
              floating: false,
            },
            yAxis: {
              title: {
                text: "Frequency",
              },
            },
            xAxis: {
              type: "datetime",
            },
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              margin: 20,
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };

          for (var key in res.data.choices) {
            if (res.data.choices.hasOwnProperty(key)) {
              config.series.push({
                name: res.data.choices[key].label,
                data: [],
              });
              res.data.choices[key].dates.forEach((date) => {
                let index = config.series.findIndex(
                  (obj) => obj.name === res.data.choices[key].label
                );
                config.series[index].data.push([
                  Date.parse(date.day),
                  date.count,
                ]);
              });
            }
          }
          break;
        case "pie":
          config = {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
              spacingTop: 80,
              shadow: {
                color: "black",
                opacity: 0.2,
                width: 2,
                offsetX: -0.2,
              },
            },
            subtitle: {
              text: res.data.surveyTitle,
              align: "Left",
              x: 20,
              y: -50,
              floating: true,
            },
            title: {
              text: res.data.fieldName,
              align: "Left",
              x: 20,
              y: -25,
              floating: false,
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color: "black",
                  },
                },
              },
            },
            exporting: {
              buttons: {
                deleteButton: {
                  enabled: true,
                  text: "Delete",
                  symbolFill: "#AA3939",
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Choices",
                colorByPoint: true,
                data: [],
              },
            ],
          };

          for (var key in res.data.choices) {
            if (res.data.choices.hasOwnProperty(key)) {
              config.series[0].data.push({
                name: res.data.choices[key].label,
                y: res.data.choices[key].percent,
              });
            }
          }
          break;
        case "basic":
          config = res.data;
          break;
      }

      let payload = {
        config: config,
        values: values,
        savedInvest: getState().dashboard.savedInvest,
      };

      dispatch({
        type: GOT_GRAPH,
        payload: payload,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GRAPH_ERROR,
        payload:
          "Error creating graph. Please try again, or contact an administrator.",
      });
      dispatch(manageError(error));
    }
  };
}
