import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";

export const theme = createMuiTheme({
  typography: {
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    body1: {
      color: "#272a2e",
      fontSize: 14,
      lineHeight: "20px",
    },
    h1: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: "36px",
      color: "#272a2e",
      "@media screen and (max-width: 991px)": {
        fontSize: 24,
        lineHeight: "30px",
      },
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "30px",
      color: "#272a2e",
    },
    h4: {
      textAlign: "left",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
      color: "#272a2e",
    },
  },
  palette: {
    primary: { main: "#006ef5" },
    secondary: { main: "#ea3546" },
    error: red,
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
      root: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
        padding: "9px 12px",
        "&:hover": {
          backgroundImage:
            "linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1))",
        },
      },
      outlined: {
        padding: "9px 11px",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
      sizeSmall: {
        padding: "4px 12px",
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow:
          "0 0 0 1px #ebeff2, 0 1px 10px 1px rgba(15, 15, 15, 0.05), 0 1px 3px 1px rgba(15, 15, 15, 0.05)",
      },
    },
  },
});
