import React from "react";
import TsDashboardHeader from "../../shared/TsDashboardHeader";
import moment from "moment/moment";
import "./activity.css";

const ActivityMonitor = ({
  activities,
  handlePageChange,
  currentPage,
  lastPage,
}) => {
  const getActionFromSubtype = (subtype) => {
    if (subtype.includes("CREATE")) {
      return "created";
    } else if (subtype.includes("UPDATE_POST_INVITE")) {
      return "invited";
    } else if (subtype.includes("UPDATE")) {
      return "updated";
    } else if (subtype.includes("IMPORT")) {
      return "imported";
    } else if (subtype.includes("DELETE")) {
      return "deleted";
    } else if (subtype.includes("UNARCHIVE")) {
      return "unarchived";
    } else if (subtype.includes("ARCHIVE")) {
      return "archived";
    } else if (subtype.includes("UNLOCK")) {
      return "unlocked";
    } else if (subtype.includes("LOCK")) {
      return "locked";
    } else if (subtype.includes("JOIN")) {
      return "joined";
    }
    return "action";
  };

  const getType = (activity) => {
    switch (activity.type) {
      case "INVESTIGATION":
        if (activity.subType.includes("UPDATE_POST_INVITE")) {
          return (
            <>
              <b>{activity.difference.newValues.email}</b> to the study
            </>
          );
        } else if (activity.subType.includes("JOIN")) {
          return <>the study</>;
        }
        return (
          <>
            study <b>{activity.difference.newValues.title}</b>
          </>
        );

      case "INVESTIGATION_LINK":
        return <>sharable study link</>;
      case "EXPORT":
        return (
          <>
            export for form <b>{activity.difference.newValues.title}</b>
          </>
        );

      case "CASE":
        return (
          <>
            case <b>{activity.difference.newValues.label}</b>
          </>
        );

      case "RESPONSE":
        return (
          <>
            entry with form <b>{activity.difference.newValues.title}</b>
          </>
        );

      case "SURVEY":
        return (
          <>
            form <b>{activity.difference.newValues.title}</b>
          </>
        );

      case "GROUP":
        if (
          activity.subType.includes("GROUP_CREATE") ||
          activity.subType.includes("GROUP_UPDATE") ||
          activity.subType.includes("GROUP_DELETE")
        ) {
          return (
            <>
              group <b>{activity.difference.newValues.name}</b>
            </>
          );
        }
        break;

      case "FORM_TRANSLATION":
        return (
          <>
            form translation for{" "}
            <b>
              {activity.difference.newValues.title ||
                activity.difference.initialValues.title}
            </b>
          </>
        );

      default:
        return "";
    }
  };
  return (
    <div className="container">
      <TsDashboardHeader text="Activity Monitor" />
      <div elevation={4} className="activityPaper">
        {activities.map((activity) => (
          <div key={activity.id} className="activityRow">
            <div className="activityRowFirstDiv">
              {activity.activityOwner.name}({activity.activityOwner.email}){" "}
              {getActionFromSubtype(activity.subType)} {getType(activity)}
            </div>
            <div className="activityRowSecondDiv">
              {moment(activity.createdAt).format("MM-DD-yyyy hh:mm a")}
            </div>
          </div>
        ))}
        <div className="paginationBar">
          <div className="paginationNavigationButton">
            <button
              className="paginationButton"
              onClick={() => handlePageChange(false)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="paginationButton"
              onClick={() => handlePageChange(true)}
              disabled={lastPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityMonitor;
