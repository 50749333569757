import axios from "axios";

import signString from "../middleware/signatureSigning";
import { NEW_BACKEND_URL } from "../middleware/networking";

import { manageError } from "./errors";

export const GROUP_READ = "GROUP_READ";
export const GROUP_DELETED = "GROUP_DELETED";
export const GROUP_UPDATED = "GROUP_UPDATED";
export const INVESTIGATION_GROUP_CREATED = "INVESTIGATION_GROUP_CREATED";
export const INVESTIGATION_GROUPS_READ = "INVESTIGATION_GROUPS_READ";
export const GROUP_LINK_CREATED = "GROUP_LINK_CREATED";
export const GROUP_LINK_READ = "GROUP_LINK_READ";
export const GROUP_LINK_UPDATED = "GROUP_LINK_UPDATED";
export const GROUP_LINK_DELETED = "GROUP_LINK_DELETED";
export const GROUP_INVITE_CREATED = "GROUP_INVITE_CREATED";

export const deleteGroup = (identifier) => (dispatch) => {
  const uri = `${NEW_BACKEND_URL}/group/${identifier}`;
  const signingObj = signString("DELETE", uri);
  return axios
    .delete(uri, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    })
    .then((res) => {
      dispatch({
        type: GROUP_DELETED,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(manageError(error));
    });
};

export const patchGroup = (identifier, payload) => (dispatch) => {
  const uri = `${NEW_BACKEND_URL}/group/${identifier}`;
  const signingObj = signString("PATCH", uri);
  return axios
    .patch(uri, payload, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    })
    .then((res) => {
      dispatch({
        type: GROUP_UPDATED,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(manageError(error));
    });
};

export const postGroup = (identifier, payload) => (dispatch) => {
  const uri = `${NEW_BACKEND_URL}/investigation/${identifier}/groups`;
  const signingObj = signString("POST", uri);
  return axios
    .post(uri, payload, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    })
    .then((res) => {
      dispatch({
        type: INVESTIGATION_GROUP_CREATED,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(manageError(error));
    });
};

export const getGroups = (identifier) => (dispatch) => {
  const uri = `${NEW_BACKEND_URL}/investigation/${identifier}/groups?withUsers=true`;
  const signingObj = signString("GET", uri);
  return axios
    .get(uri, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    })
    .then((res) => {
      const groups = res.data;
      const finalGroups = [];

      for (const group of groups) {
        const activeUsers = group.activeUsers ? group.activeUsers : [];
        const pendingUsers = group.pendingUsers ? group.pendingUsers : [];
        let userCount = activeUsers.length + pendingUsers.length;

        const users = { activeUsers, pendingUsers };

        finalGroups.push({ ...group, users, userCount });
      }

      // dispatch({
      //   type: GROUP_READ,
      //   payload,
      // });
      // return payload;
      dispatch({
        type: INVESTIGATION_GROUPS_READ,
        payload: finalGroups,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      dispatch(manageError(error));
    });
};

export const postGroupLink = (identifier, payload) => async (dispatch) => {
  try {
    const uri = `${NEW_BACKEND_URL}/group/${identifier}/link`;
    const signingObj = signString("POST", uri);
    const res = await axios.post(`${uri}`, payload, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });
    dispatch({
      type: GROUP_LINK_CREATED,
    });
    return res;
  } catch (error) {
    console.error(error);
    dispatch(manageError(error));
  }
};

export const patchGroupLink = (identifier) => async (dispatch) => {
  try {
    const uri = `${NEW_BACKEND_URL}/group/${identifier}/link`;
    const signingObj = signString("PATCH", uri);
    const res = await axios.patch(
      `${uri}`,
      {},
      {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      }
    );
    dispatch({
      type: GROUP_LINK_UPDATED,
    });
    return res;
  } catch (error) {
    console.error(error);
    dispatch(manageError(error));
  }
};

export const deleteGroupLink = (identifier) => async (dispatch) => {
  try {
    const uri = `${NEW_BACKEND_URL}/group/${identifier}/link`;
    const signingObj = signString("DELETE", uri);
    const res = await axios.delete(`${uri}`, {
      headers: {
        "X-Date": signingObj.date,
        Authorization: signingObj.string,
      },
    });
    dispatch({
      type: GROUP_LINK_DELETED,
    });
    return res;
  } catch (error) {
    console.error(error);
    dispatch(manageError(error));
  }
};

export const postGroupInvite =
  (investigationIdentifier, groupIdentifier, email, permissions) =>
  async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/investigation/${investigationIdentifier}/invites`;
      const signingObj = signString("POST", uri);
      const payload = { invites: [{ email, groupIdentifier, ...permissions }] };
      const res = await axios.post(`${uri}`, payload, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      dispatch({
        type: GROUP_INVITE_CREATED,
      });
      return res;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };

export const deleteGroupUser =
  (groupIdentifier, userIdentifier) => async (dispatch) => {
    try {
      const uri = `${NEW_BACKEND_URL}/group/${groupIdentifier}/user/${userIdentifier}`;
      const signingObj = signString("DELETE", uri);
      const res = await axios.delete(uri, {
        headers: {
          "X-Date": signingObj.date,
          Authorization: signingObj.string,
        },
      });
      return res;
    } catch (error) {
      console.error(error);
      dispatch(manageError(error));
    }
  };
